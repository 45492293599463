import React from 'react';
import useAttackStore from '../../store/attackStore';
import useAuthStore from '../../store/authStore';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

const AttackComponent = () => {
  const { username, result, error, setUsername, attack } = useAttackStore();
  const { user, isAuthenticated } = useAuthStore();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isAuthenticated) {
      console.log('Initiating attack...');
      await attack();
      console.log('Attack completed');
    } else {
      console.error('User not authenticated');
    }
  };

  if (!isAuthenticated) {
    return <p className="text-center text-red-500">Please log in to perform attacks.</p>;
  }

  return (
    <div className="h-screen w-full bg-cover bg-center flex justify-center items-center"
    
      >
      <div className="bg-black bg-opacity-60 rounded-lg p-8 text-left shadow-lg"
        style={{ border: '2px solid #DAA520', color: '#fff' }}>
        <h2 className="text-4xl font-bold mb-4 text-yellow-500 text-center">{t('Attack')}</h2>

        <form onSubmit={handleSubmit} className="flex flex-col items-center gap-4">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={t('VictimUsername')}
            required
            className="p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-yellow-500 w-full"
          />
          <button
            type="submit"
            className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300 w-full">
            Attack
          </button>
        </form>

        {result && (
          <div className="mt-6 text-white">
            <h3 className="text-2xl font-semibold text-yellow-500 mb-2">{result.message}</h3>
            {result.gains && (
              <p>
                Strength: ++{result.gains.strength},<br/>
                Intelligence: ++{result.gains.intelligence},<br/>
                Tolerance: ++{result.gains.charisma}
              </p>
            )}

            {result.lost && (
              <p>
                Strength: --{result.lost.strength},<br/>
                Intelligence: --{result.lost.intelligence},<br/>
                Tolerance: --{result.lost.charisma}
              </p>
            )}
          </div>
        )}

        {error && <p className="mt-4 text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default AttackComponent;
