import React, { useEffect } from 'react';
import useAuthStore from '../store/authStore';  // Import Zustand store

const Logout = () => {
    const { logout } = useAuthStore();  // Logout function from Zustand

    useEffect(() => {
        const handleLogout = async () => {
            await logout();  // Perform logout logic
            window.location.href = '/login';  // Redirect to login page after logout
        };

        handleLogout();  // Call the logout function
    }, [logout]);

    return <div>Logging out...</div>;  // Optional: Display a message while logging out
};

export default Logout;

