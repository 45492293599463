import React, { useEffect, useState } from 'react';
import useWeaponsStore from '../store/weaponsStore';
import {
  fetchUserId,
  fetchWeaponsItems,
  fetchUserItems,
  buyItem,
  sellItem,
} from '../services/weaponsService';
import { checkUserPremium } from '../services/usersService';
import useAuthStore from '../store/authStore';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

const Weapons = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [isUserPremium, setIsUserPremium] = useState(false);

  const {
    weaponsItems,
    balance,
    userItems,
    message,
    userId,
    setWeaponsItems,
    setBalance,
    setUserItems,
    setMessage,
    setUserId,
  } = useWeaponsStore();  // Access Zustand state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userId, money } = await fetchUserId();
        setUserId(userId);
        setBalance(money);

        const weaponsData = await fetchWeaponsItems();
        setWeaponsItems(weaponsData);

        const userItemsData = await fetchUserItems(userId);
        setUserItems(userItemsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [setUserId, setBalance, setWeaponsItems, setUserItems]);

  useEffect(() => {
    const checkPremiumStatus = async () => {
      if (user && user.id) {
        try {
          const premiumData = await checkUserPremium(user.id);
          setIsUserPremium(premiumData.premium);
        } catch (error) {
          console.error('Error checking premium status:', error);
        }
      }
    };
    checkPremiumStatus();
  }, [user]);

  const handleBuy = async (itemId) => {
    try {
      const item = weaponsItems.find(item => item.id === itemId);
      if (item && item.name === 'Armored Car' && !isUserPremium) {
        setMessage('Only VIP members can purchase the Armored Car.');
        return;
      }

      const response = await buyItem(userId, itemId);
      setMessage(response.message);

      const { money } = await fetchUserId();  // Update balance
      setBalance(money);

      const userItemsData = await fetchUserItems(userId);  // Re-fetch user items
      setUserItems(userItemsData);
    } catch (error) {
      console.error('Error buying item:', error);
      setMessage('Failed to buy item');
    }
  };

  const handleSell = async (itemId) => {
    try {
      const response = await sellItem(userId, itemId);
      setMessage(response.message);

      const { money } = await fetchUserId();  // Update balance
      setBalance(money);

      const userItemsData = await fetchUserItems(userId);  // Re-fetch user items
      setUserItems(userItemsData);
    } catch (error) {
      console.error('Error selling item:', error);
      setMessage('Failed to sell item');
    }
  };

  if (!weaponsItems.length) {
    return <p>Loading weapons items...</p>;
  }

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center mobile-friendly"
    >
      <div
        className="weapons-container  bg-black bg-opacity-60 p-8 rounded-lg text-left shadow-lg max-w-lg w-full mb-20 mt-20"
        style={{
          transform: 'translateY(0px)', // Move the container 50px closer to the top
        }}
      >
        <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Weapons')}</h2>
        <div className="text-white">
          <p>
            <p dangerouslySetInnerHTML={{ __html: t('WeaponsDesc') }} />
          </p>
          <br />
          <p className="text-red-600 font-bold" dangerouslySetInnerHTML={{ __html: t('WeaponsDesc2') }} />
          <br />
        </div>
        <p className="text-yellow-500">{t('Balance')}: {balance}</p>
        {message && <p className="text-red-500">{message}</p>}

        <ul className="mb-6">
          {weaponsItems.map((item) => (
            <li key={item.id} className="mb-4 text-white flex items-start justify-between border-b border-gray-700 pb-4">
              {/* Item Name with fixed width */}
              <span className="font-bold text-yellow-400 text-lg w-1/3">{t(item.name)}</span>

              {/* Item Details */}
              <div className="text-gray-300 text-sm w-1/3">
                <p><span className="text-yellow-500 font-semibold">Price:</span> {item.price}</p>
                <p><span className="text-yellow-500 font-semibold">Attack:</span> {item.damage}</p>
                <p><span className="text-yellow-500 font-semibold">Defense:</span> {item.defense}</p>
              </div>

              {/* Button */}
              {item.name === 'Machine Gun' && !isUserPremium ? (
                <button
                  className="ml-4 bg-gray-500 text-white px-4 py-2 rounded cursor-not-allowed"
                  disabled
                >
                  VIP Only
                </button>
              ) : (
                <button
                  className="ml-4 bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300"
                  onClick={() => handleBuy(item.id)}
                >
                  {t('Buy')}
                </button>
              )}
            </li>
          ))}
        </ul>


        <h3 className="text-2xl font-semibold mb-4 text-yellow-500">{t('MyItems')}</h3>
        {userItems.length === 0 ? (
          <p className='text-white'>{t('NoItems')}</p>
        ) : (
          <ul>
              {userItems.map((item) => {
                const matchingWeapon = weaponsItems.find((weapon) => weapon.id === item.item_id); // Match with weaponsItems
                const sellPrice = matchingWeapon ? matchingWeapon.price / 2 : 'N/A'; // Calculate sell price or fallback to 'N/A'

                return (
                  <li key={item.item_id} className="mb-4 text-white flex justify-between items-center">
                    <span>{t(item.item_name)} - Quantity: {item.quantity} - Sell Price: {sellPrice}</span>
                    <button
                      className="ml-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                      onClick={() => handleSell(item.item_id)}
                    >
                      {t('Sell')}
                    </button>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );


};

export default Weapons;
