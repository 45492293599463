import React, { useEffect, useState } from 'react';
import useMessageStore from '../store/privateMessageStore';
import {
  fetchInboxMessages,
  fetchOutboxMessages,
  sendMessage,
  fetchSingleInboxMessage,
  fetchSingleOutboxMessage,
  fetchUserProfile,
  markAsRead
} from '../services/privateMessageService';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

const PrivateMessage = () => {
  const { t } = useTranslation();
  const { send, inbox, outbox, setInbox, setOutbox, username, setUsername, setUnreadCount } = useMessageStore();
  const [receiver, setReceiver] = useState('');
  const [messageTopic, setMessageTopic] = useState('');
  const [messageText, setMessageText] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('inbox'); // For tabs

  useEffect(() => {
    const fetchUserAndMessages = async () => {
      try {
        const fetchedUsername = await fetchUserProfile();
        setUsername(fetchedUsername);

        const inboxData = await fetchInboxMessages();
        const outboxData = await fetchOutboxMessages();
        setInbox(inboxData);
        setOutbox(outboxData);

        // Calculate the unread count
        const unreadMessages = inboxData.filter(message => !message.read);
        setUnreadCount(unreadMessages.length);
      } catch (error) {
        console.error('Error fetching user profile or messages:', error);
      }
    };

    fetchUserAndMessages();
  }, [setUsername, setInbox, setOutbox]);

  const handleSendMessage = async () => {
    if (!receiver || !messageTopic || !messageText) {
      alert('Please fill in all fields');
      return;
    }

    if (receiver === username) {
      alert('You cannot send a message to yourself');
      return;
    } else if (receiver === 'admin') {
      alert('You cannot send a message to the admin');
      return;
    }

    try {
      const response = await sendMessage(receiver, messageTopic, messageText);

      // Clear the form after successful send
      setReceiver('');
      setMessageTopic('');
      setMessageText('');

      // Show success message
      alert('Message sent successfully');

      // Refresh messages
      const outboxData = await fetchOutboxMessages();
      setOutbox(outboxData);
    } catch (error) {
      // Show user-friendly error message
      const errorMessage = error.response?.data?.message || 'Failed to send message. Please try again.';
      alert(errorMessage);
    }
  };

  const handleInboxClick = async (message) => {
    try {
      // Mark message as read in the backend
      await markAsRead(message.message_id || message.id);

      // Fetch the updated inbox to get the current read status of all messages
      const inboxData = await fetchInboxMessages();
      setInbox(inboxData); // Update Zustand inbox with the latest data

      // Set the selected message in the modal
      const fetchedMessage = await fetchSingleInboxMessage(message.receiver, message.message_id || message.id);
      setSelectedMessage(fetchedMessage[0]); // Set first element from array
      setIsModalOpen(true);

      // Update unread count in Zustand
      const unreadMessages = inboxData.filter(msg => !msg.read);
      setUnreadCount(unreadMessages.length);
    } catch (error) {
      console.error('Error fetching inbox message:', error);
    }
  };


  const handleOutboxClick = async (message) => {
    try {
      const fetchedMessage = await fetchSingleOutboxMessage(message.message_id || message.id);
      setSelectedMessage(fetchedMessage[0]);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching outbox message:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMessage(null);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center mobile-friendly"
    >
      <div
        className="private-messages-container bg-black bg-opacity-60 p-8 rounded-lg text-left shadow-lg max-w-lg w-full mb-20 mt-20"
        style={{
          transform: 'translateY(0px)', // Move the container 50px closer to the top
        }}
      >
        <div className="min-h-screen bg-gray-300 p-8">
          <h2 className="text-yellow-500 text-3xl font-bold mb-6">{t('PrivateMessages')}</h2>

          {/* Tabs for Inbox and Outbox */}
          <div className="flex space-x-4 mb-6">
            <button
              className={`px-4 py-2 rounded-lg ${activeTab === 'send' ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-600'}`}
              onClick={() => setActiveTab('send')}
            >
              {t('Send')}
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${activeTab === 'inbox' ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-600'}`}
              onClick={() => setActiveTab('inbox')}
            >
              {t('Inbox')}
            </button>
            <button
              className={`px-4 py-2 rounded-lg ${activeTab === 'outbox' ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-600'}`}
              onClick={() => setActiveTab('outbox')}
            >
              {t('Outbox')}
            </button>
          </div>

          {/* Send Content */}
          {activeTab === 'send' && (
            <div>
              <h3 className="text-xl font-semibold">{t('SendMessage')}</h3>
              <div className="inbox-container" style={{ border: '2px solid black', padding: '15px', margin: '10px', maxWidth: '400px' }}>
                {/* Send Message Form */}
                <div className="bg-white p-6 shadow-md rounded-lg mb-6">
                  <h3 className="text-xl font-semibold mb-4">{t('SendMessage')}</h3>
                  <div className="flex flex-col space-y-4">
                    <div>
                      <label>{t('Receiver')}:</label>&nbsp;
                      <input
                        type="text"
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                        placeholder="Receiver"
                        className="p-2 border border-gray-300 rounded-lg w-full"
                      />
                    </div>
                    <div>
                      <label>{t('Topic')}:</label>&nbsp;
                      <input
                        type="text"
                        value={messageTopic}
                        onChange={(e) => setMessageTopic(e.target.value)}
                        placeholder="Topic"
                        className="p-2 border border-gray-300 rounded-lg w-full"
                      />
                    </div>
                    <div>
                      <label>{t('YourMessage')}:</label><br />
                      <textarea
                        rows="4"
                        cols="50"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        placeholder="Message"
                        className="p-2 border border-gray-300 rounded-lg w-full"
                      />
                    </div>
                    <button
                      onClick={handleSendMessage}
                      className="bg-yellow-400 text-white px-4 py-2 rounded-lg hover:bg-yellow-500"
                    >
                      {t('Send')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Inbox/Outbox Content */}
          {activeTab === 'inbox' && (
            <div>
              <h3 className="text-xl font-semibold">{t('Inbox')}</h3>
              <div className="inbox-container" style={{ border: '2px solid black', padding: '15px', margin: '10px', maxWidth: '400px' }}>
                {inbox.length > 0 ? (
                  <ul>
                    {inbox.slice(0, 20).map((message) => (
                      <li
                        key={message.message_id || message.id}
                        onClick={() => handleInboxClick(message, inbox)}
                        className={`cursor-pointer hover:bg-gray-100 p-2 rounded-lg ${!message.read ? 'bg-yellow-100' : ''}`}
                      >
                        {message.sender} - {message.topic}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t('NoMessages')}</p>
                )}
              </div>
            </div>
          )}

          {activeTab === 'outbox' && (
            <div>
              <h3 className="text-xl font-semibold">{t('Outbox')}</h3>
              <div className="outbox-container" style={{ border: '2px solid black', padding: '15px', margin: '10px', maxWidth: '400px' }}>
                {outbox.length > 0 ? (
                  <ul>
                    {outbox.slice(0, 20).map((message) => (
                      <li
                        key={message.message_id || message.id}
                        onClick={() => handleOutboxClick(message)}
                        className="cursor-pointer hover:bg-gray-100 p-2 rounded-lg"
                      >
                        {message.receiver} - {message.topic}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t('NoMessages')}</p>
                )}
              </div>
            </div>
          )}

          {/* Modal with Tailwind */}
          {isModalOpen && selectedMessage && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
              <div className="relative bg-white rounded-lg shadow-xl mx-4 max-w-md w-full">
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-gray-900">{selectedMessage.topic}</h3>
                    <button
                      onClick={closeModal}
                      className="text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <span className="text-2xl">&times;</span>
                    </button>
                  </div>
                  <div className="space-y-3">
                    <p><span className="font-semibold">{t('Sender')}: {selectedMessage.sender}</span></p>
                    {selectedMessage.receiver && (
                      <p><span className="font-semibold">{t('Receiver')}: {selectedMessage.receiver}</span></p>
                    )}
                    <p><span className="font-semibold">{t('Message')}:</span></p>
                    <p className="text-gray-600 whitespace-pre-wrap">{selectedMessage.message}</p>
                    <p className="text-sm text-gray-500">{formatDate(selectedMessage.timestamp)}</p>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      onClick={closeModal}
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                    >
                      {t('Close')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivateMessage;
