import React, { useState } from 'react';
import Dashboard from '../Content/Dashboard';
import UserProfile from '../Content/UserProfile';
import useAuthStore from '../../store/authStore';
import PremiumModal from '../Content/PremiumModal';

const Sidebar = () => {
    const { user, isAuthenticated } = useAuthStore();
    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    if (user && isAuthenticated) {
        return (
            <div className="flex flex-col items-center p-5 min-h-screen">
                {/* Dashboard Section */}
                <div className="w-full flex items-center justify-center">
                    <Dashboard />
                </div>
                <br />

                <div className="w-full flex items-center justify-center">
                    <button onClick={handleOpenModal} className="text-yellow-500 hover:underline">
                        <p className="text-3xl">👑 <br />Buy <br />Premium</p>
                    </button>
                </div>

                {/* User Profile Section */}
                <div className="w-full flex items-center justify-center">
                    <UserProfile />
                </div>

                {/* Premium Modal */}
                <PremiumModal isOpen={isModalOpen} onClose={handleCloseModal} />
                <br />
            </div>
        );
    }
    return null;
};

export default Sidebar;
