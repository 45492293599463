import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/styles.css'
import reportWebVitals from './reportWebVitals';  // Optional performance analytics

const root = ReactDOM.createRoot(document.getElementById('root'));  // Get the root div from index.html
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optionally measure performance (optional)
reportWebVitals();
