// frontend/src/services/weaponsService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserId = async () => {
  const response = await axios.get(`${API_URL}/stats`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchWeaponsItems = async () => {
  const response = await axios.get(`${API_URL}/weapons`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchUserItems = async (userId) => {
  const response = await axios.get(`${API_URL}/weapons/${userId}`, {
    withCredentials: true,
  });
  return response.data;
};

export const buyItem = async (userId, itemId) => {
  const response = await axios.post(`${API_URL}/weapons/buy`, {
    userId,
    itemId,
  }, {
    withCredentials: true,
  });
  return response.data;
};

export const sellItem = async (userId, itemId) => {
  const response = await axios.post(`${API_URL}/weapons/sell`, {
    userId,
    itemId,
  }, {
    withCredentials: true,
  });
  return response.data;
};
