// frontend/src/services/armoryService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserId = async () => {
  try {
    const response = await axios.get(`${API_URL}/stats`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchArmoryItems = async () => {
  const response = await axios.get(`${API_URL}/armory`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchUserItems = async (userId) => {
  const response = await axios.get(`${API_URL}/armory/${userId}`, {
    withCredentials: true,
  });
  return response.data;
};

export const buyItem = async (userId, itemId) => {
  const response = await axios.post(`${API_URL}/armory/buy`, {
    userId,
    itemId,
  }, {
    withCredentials: true,
  });
  return response.data;
};

export const sellItem = async (userId, itemId) => {
  const response = await axios.post(
    `${API_URL}/armory/sell`,
    { userId, itemId },
    { withCredentials: true }
  );
  return response.data; // { message }
};
