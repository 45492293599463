import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to Gangbangers! These terms and conditions outline the rules and regulations for the use of our website.
      </p>
      <h2>License</h2>
      <p>
        Unless otherwise stated, Gangbangers and/or its licensors own the intellectual property rights for all material on the site.
      </p>
      <h2>Restrictions</h2>
      <p>You are restricted from the following:</p>
      <ul>
        <li>Publishing any material from the site without permission.</li>
        <li>Using the website in any way that damages the site.</li>
        <li>Engaging in any unlawful activity on the site.</li>
      </ul>
      {/* Add more terms as needed */}
    </div>
  );
};

export default TermsAndConditions;
