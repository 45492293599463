// frontend/src/services/privateMessageService.jsx
import axios from 'axios';
import useMessageStore from '../store/privateMessageStore';
import useAuthStore from '../store/authStore';

const API_URL = process.env.REACT_APP_API_URL;
let eventSource = null;

/**
 * Initialize SSE connection
 * @param {Function} onMessageCallback - Callback to handle incoming messages
 */
export const initializeSSE = (onMessageCallback) => {
  if (eventSource) {
    console.warn('SSE connection is already established.');
    return;
  }

  eventSource = new EventSource(`${API_URL}/sse`);

  eventSource.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      onMessageCallback(data);
    } catch (error) {
      console.error('Error parsing SSE message:', error);
    }
  };

  eventSource.onerror = (error) => {
    console.error('SSE connection error:', error);
    closeSSE(); // Close the connection on error
  };
};

/**
 * Close the SSE connection
 */
export const closeSSE = () => {
  if (eventSource) {
    eventSource.close();
    eventSource = null;
  }
};

// Fetch all inbox messages for the logged-in user
export const fetchInboxMessages = async () => {
  try {
    const { username } = useMessageStore.getState();
    if (!username) {
      console.error('Username is missing');
      return []; // return an empty array instead of null
    }
    const response = await axios.get(`${API_URL}/inbox/${username}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error fetching inbox messages:', error);
    return []; // return an empty array instead of null
  }
};

// Fetch a single inbox message by messageId
export const fetchSingleInboxMessage = async (username, messageId) => {
  if (!messageId) {
    throw new Error('Message ID is missing');
  }
  const response = await axios.get(`${API_URL}/inbox/${username}/${messageId}`, { withCredentials: true });
  return response.data;
};

// Send /markAsRead to the backend if message opened from frontend
export const markAsRead = async (messageId) => {
  const { username } = useMessageStore.getState();
  const response = await axios.post(
    `${API_URL}/inbox/${username}/${messageId}/markAsRead`,
    {}, // Body is empty since it's a mark-as-read action
    { withCredentials: true } // Ensure cookies or tokens are included
  );
  return response.data;
};


// Fetch all outbox messages for the logged-in user
export const fetchOutboxMessages = async () => {
  const { username } = useMessageStore.getState();
  const response = await axios.get(`${API_URL}/outbox/${username}`, { withCredentials: true });
  return response.data;
};

// Fetch a single outbox message by messageId
export const fetchSingleOutboxMessage = async (messageId) => {
  const { username } = useMessageStore.getState(); // Get the username from the store
  const response = await axios.get(`${API_URL}/outbox/${username}/${messageId}`, { withCredentials: true });
  return response.data;
};

// Send a message
export const sendMessage = async (receiver, messageTopic, messageText) => {
  const { username } = useMessageStore.getState();
  const response = await axios.post(`${API_URL}/send-message`, {
    sender: username,
    receiver,
    topic: messageTopic,
    message: messageText,
  }, { withCredentials: true });
  return response.data;
};

// Fetch the username from the existing 'playerStats' or 'dashboard' endpoint
//export const fetchUserProfile = async () => {
//  try {
//    const { user } = useAuthStore.getState();
//    const response = await axios.get(`${API_URL}/profile/${user.id}`, {
//      withCredentials: true,
//    });
//    return response.data.username;
//  } catch (error) {
//    console.error('Failed to fetch user profile:', error);
//    throw error;
//  }
//};

//export const fetchUserProfile = async () => {
//  try {
//    const { user } = useAuthStore.getState();
//    const response = await axios.get(`${API_URL}/profile/${user.id}`, {
//      withCredentials: true,
//    });
//    const username = response.data.username;
//    useMessageStore.getState().setUsername(username); // Set the username in the useMessageStore
//    return username;
//  } catch (error) {
//    console.error('Failed to fetch user profile:', error);
//    throw error;
//  }
//};

export const fetchUserProfile = async () => {
  try {
    const { user } = useAuthStore.getState();
    if (!user?.id) {
      throw new Error('User is not logged in.');
    }

    const response = await axios.get(`${API_URL}/profile/${user.id}`, { withCredentials: true });
    const username = response.data.username;

    // Set the username in the message store
    useMessageStore.getState().setUsername(username);

    return username;
  } catch (error) {
    console.error('Failed to fetch user profile:', error);
    throw error;
  }
};


// Ensure all functions are correctly exported
export default {
  fetchInboxMessages,
  fetchOutboxMessages,
  sendMessage,
  fetchSingleInboxMessage,
  markAsRead,
  fetchSingleOutboxMessage,
  fetchUserProfile,
};
