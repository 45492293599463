import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import useAuthStore from '../../store/authStore'

const Layout = () => {
    const { isAuthenticated } = useAuthStore();
    return (
        <div
            className="min-h-screen flex flex-col"
            style={{
                backgroundImage: `url('./dashboard_wp.jpg')`,
                backgroundSize: 'cover',
            }}
        >
            {/* Header */}
            <Header />

            {/* Main content container with sidebar */}
            <div className="flex flex-grow">
                {/* Sidebar - Static position, aligned next to main content */}
                { isAuthenticated && (
                <div className="w-64 bg-black text-yellow-500 flex-shrink-0 min-h-full">
                    <Sidebar />
                </div>
                    )}

                {/* Main content area */}
                <main className="flex-grow flex justify-center items-center"
                    style={{
                        marginRight: isAuthenticated ? '16rem' : '0', // Adjust for the sidebar width
                        transition: 'margin 0.3s', // Smooth adjustment when sidebar appears or disappears
                    }}
                >
                    <Outlet />
                </main>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Layout;
