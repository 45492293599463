import { create } from 'zustand';

const useGangsStore = create((set) => ({
  gangs: [],
  userGang: null,
  gangName: '',
  message: '',
  userId: null,
  loading: true,
  gangJoinRequests: [],
  userJoinRequests: [],
  username: '',

  setGangs: (gangs) => set({ gangs }),
  setUserGang: (userGang) => set({ userGang }),
  setGangName: (gangName) => set({ gangName }),
  setMessage: (message) => set({ message }),
  setUserId: (userId) => set({ userId }),
  setLoading: (loading) => set({ loading }),
  setGangJoinRequests: (requests) => set({ gangJoinRequests: requests }),
  setUserJoinRequests: (requests) => set({ userJoinRequests: requests }),
  setUsername: (username) => set({ username }),
}));

export default useGangsStore;
