import React, { useState } from "react";
import { sendContactEmail } from "../../services/contactFormService";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [status, setStatus] = useState(""); // Status message for user feedback

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        try {
            const response = await sendContactEmail(formData);
            if (response.status === 200) {
                setStatus("Message sent successfully!");
                setFormData({ name: "", email: "", message: "" });
            } else {
                setStatus("Failed to send the message.");
            }
        } catch (error) {
            console.error("Error:", error);
            setStatus("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="flex justify-center items-center bg-gray-100">
            <form
                className="w-full max-w-lg bg-white p-8 rounded-lg shadow-lg"
                onSubmit={handleSubmit}
            >
                <div
                    className="text-center text-gray-800 mb-6"
                    dangerouslySetInnerHTML={{ __html: t("CONTACT.Contact_Text") }} // Directly using the translation
                />

                <div className="mb-4">
                    <label
                        htmlFor="name"
                        className="block text-gray-700 font-semibold mb-2"
                    >
                        {t("CONTACT.name")}
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={t("CONTACT.name_placeholder")}
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label
                        htmlFor="email"
                        className="block text-gray-700 font-semibold mb-2"
                    >
                        {t("CONTACT.email")}
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t("CONTACT.email_placeholder")}
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                        required
                    />
                </div>

                <div className="mb-6">
                    <label
                        htmlFor="message"
                        className="block text-gray-700 font-semibold mb-2"
                    >
                        {t("CONTACT.message")}
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder={t("CONTACT.message_placeholder")}
                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                        required
                    ></textarea>
                </div>

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
                >
                    {t("CONTACT.send_button")}
                </button>

                {status && (
                    <p className="mt-4 text-center text-sm text-gray-600">
                        {status}
                    </p>
                )}
            </form>
        </div>
    );
};

export default Contact;
