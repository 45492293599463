import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. This privacy policy document explains what personal data we collect and how we use it.
      </p>
      <h2>Information We Collect</h2>
      <p>We collect information in the following ways:</p>
      <ul>
        <li>Personal identification information (name, email, etc.)</li>
        <li>Data from cookies and usage tracking on the website.</li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>
        We use your information to provide and improve our services, including sending updates and managing your account.
      </p>
      {/* Add more privacy policy details as needed */}
    </div>
  );
};

export default PrivacyPolicy;
