// frontend/src/services/drugsService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserId = async () => {
  const response = await axios.get(`${API_URL}/stats`, {
    withCredentials: true,
  });
  return response.data.userId;
};

export const fetchUserDrugs = async (userId) => {
  const response = await axios.get(`${API_URL}/drugs/${userId}`, {
    withCredentials: true,
  });
  return response.data;
};

export const sellDrug = async (userId, drugId, quantity, price) => {
  const response = await axios.post(
    `${API_URL}/drugs/sell`,
    {
      user_id: userId,
      drug_id: drugId,
      quantity,
      price,
    },
    { withCredentials: true }
  );
  return response.data;
};
