// frontend/src/store/robberyStore.js
import { create } from 'zustand';
import { getRobberyRequirements } from '../services/robberyService';

const useRobberyStore = create((set) => ({
  result: '',
  robberyType: '',  // Empty string as default
  requirements: null,
  setResult: (result) => set({ result }),
  setRobberyType: (robberyType) => {
    set({ robberyType });
    if (robberyType) {  // Only fetch if robberyType is not empty
      useRobberyStore.getState().fetchRequirements(robberyType);
    }
  },
  clearResult: () => set({ result: '' }),

  fetchRequirements: async (robberyType) => {
    if (!robberyType) return; // Don't fetch if robberyType is empty
    try {
      const response = await getRobberyRequirements(robberyType);
      set({ requirements: response.requirements });
    } catch (error) {
      console.error('Failed to fetch requirements:', error);
    }
  }
}));

export default useRobberyStore;
