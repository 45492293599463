import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useRobberyStore from '../store/robberyStore';
import { attemptRobbery } from '../services/robberyService';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

const Robbery = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { result, requirements, robberyType, setResult, setRobberyType, fetchRequirements } = useRobberyStore();
  const [loading, setLoading] = useState(false);
  const [storedResult, setStoredResult] = useState('');

  useEffect(() => {
    if (robberyType) {
      fetchRequirements(robberyType);
    }
  }, [robberyType, fetchRequirements]);

  useEffect(() => {
    const savedResult = sessionStorage.getItem('result');
    if (savedResult) {
      setStoredResult(savedResult);
      sessionStorage.removeItem('result');
    }
  }, []);

  const handleRobbery = async () => {
    setLoading(true);
    try {
      const data = await attemptRobbery(robberyType);
      const formattedMessage = data.message.replace(/\n/g, '<br/>');
      setResult(formattedMessage);
      sessionStorage.setItem('result', formattedMessage);
      window.location.reload();
    } catch (error) {
      const errorMessage = error.message || 'An error occurred!';
      setResult(errorMessage);
      sessionStorage.setItem('result', errorMessage);
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen bg-cover bg-center flex justify-center items-center mobile-friendly">
      <div
        className="bg-black bg-opacity-60 rounded-lg p-8 text-left shadow-lg"
        style={{
          transform: 'translateY(-50px)',
          border: '2px solid #DAA520',
          color: '#fff',
        }}
      >
        <h1 className="text-4xl font-bold mb-4 text-yellow-500 text-center" style={{ fontFamily: "'Cinzel', serif" }}>
          {t('robbery_title')}
        </h1>

        <p className="mb-6" style={{ fontFamily: "'Cinzel', serif", fontSize: '18px', lineHeight: '1.6' }}>
          <p dangerouslySetInnerHTML={{ __html: t('robbery_description') }} />
        </p>

        <label htmlFor="target" className="block mb-2" style={{ fontFamily: "'Lora', serif", color: '#DAA520' }}>
          <p dangerouslySetInnerHTML={{ __html: t('robbery_description2') }} />
        </label>

        <select
          id="target"
          value={robberyType}
          onChange={(e) => setRobberyType(e.target.value)}
          className="p-2 rounded bg-gray-400 text-white mb-4"
        >
          <option value="">{t('Select a heist type')}</option>
          <option value="small_heist">{t('small_heist')}</option>
          <option value="bank_heist">{t('bank_heist')}</option>
          <option value="casino_heist">{t('casino_heist')}</option>
        </select>

        {requirements && (
          <div className="text-yellow-500 mb-4">
            <h3 className="text-yellow-500 mb-2"><b>{t('Requirements')}:</b></h3>
            <p>{t('Stamina Cost')}: {requirements.staminaCost}</p>
            <p>{t('Strength')}: {requirements.requiredStats?.strength || 'N/A'}</p>
            <p>{t('Intelligence')}: {requirements.requiredStats?.intelligence || 'N/A'}</p>
            <p>{t('Charisma')}: {requirements.requiredStats?.charisma || 'N/A'}</p>
          </div>
        )}

        <button
          onClick={handleRobbery}
          className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300"
          disabled={loading}
        >
          💣 {t('Go_For_It')}
        </button>

        {storedResult && (
          <p className="mt-4 text-white font-serif" dangerouslySetInnerHTML={{ __html: storedResult }}></p>
        )}
        {result && (
          <p className="mt-4 text-white font-serif" dangerouslySetInnerHTML={{ __html: result }}></p>
        )}
      </div>
    </div>
  );
};

export default Robbery;
