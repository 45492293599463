// frontend/src/services/playerStatsService.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPlayerStats = async () => {
    const response = await fetch(`${API_URL}/stats`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to fetch stats');
    }

    return response.json();
};

export const fetchUserProfile = async (userId) => {
    const response = await axios.get(`${API_URL}/profile/${userId}`, {
      withCredentials: true,
    });
    return response.data;
  };