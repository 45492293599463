// frontend/src/services/gangsService.js
import axios from 'axios';

import useGangsStore from '../store/gangsStore';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserId = async () => {
  const response = await axios.get(`${API_URL}/stats`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchUserGang = async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/gangs/user/${userId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchGangMembers = async (gangId) => {
  const response = await axios.get(`${API_URL}/gangs/${gangId}`);
  return response.data;
};

export const fetchAllGangs = async () => {
  const response = await axios.get(`${API_URL}/gangs`, {
    withCredentials: true,
  });
  return response.data;
};

export const sendJoinRequest = async (gangId, userId) => {
  const response = await axios.post(`${API_URL}/gangs/${gangId}/join-request`, {
    userId,
  }, {
    withCredentials: true,
  });
  return response.data;
};

export const createGang = async (userId, gangName) => {
  const response = await axios.post(`${API_URL}/gangs/create`, {
    user_id: userId, // Use userId for both leader_id and user_id
    name: gangName
  });
  return response.data;
};

export const quitGang = async (userId, gangId) => {
  const response = await axios.post(`${API_URL}/gangs/quit`, {
    userId,
    gangId,
  }, {
    withCredentials: true,
  });
  return response.data;
};

// Disband gang function
export const disbandGang = async (gangId) => {
  if (!gangId) {
    console.error('Gang ID is undefined or invalid:', gangId);
    return;
  }
  const response = await axios.delete(`${API_URL}/gangs/${gangId}/disband`, {
    withCredentials: true,  // use credentials if needed
  });
  return response.data;
};

export const fetchGangJoinRequests = async (gangId) => {
  const response = await axios.get(`${API_URL}/gangs/${gangId}/join-requests`, {
    withCredentials: true,
  });
  return response.data.joinRequests || [];
};


export const declineJoinRequest = async (gangId, username) => {
  const response = await axios.post(`${API_URL}/gangs/${gangId}/decline-join-request`, {
    username,
  }, {
    withCredentials: true,
  });
  return response.data;
};

export const acceptJoinRequest = async (gangId, username) => {
  const response = await axios.post(`${API_URL}/gangs/${gangId}/accept-join-request`, {
    username,
  }, {
    withCredentials: true,
  });
  return response.data;
};


export const fireGangMembers = async (gangId, username) => {
  try {
    console.log('Attempting to fire member:', { gangId, username }); // Debug log
    const response = await axios.post(`${API_URL}/gangs/${gangId}/fire-gang-member`, {
      username,
    }, {
      withCredentials: true,
    });
    console.log('Fire response:', response.data); // Debug log
    return response.data;
  } catch (error) {
    console.error('Fire request failed:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    throw error; // Re-throw to handle in component
  }
};

export const promoteGangMember = async (gangId, username) => {
  const response = await axios.post(`${API_URL}/gangs/${gangId}/promote-member`, {
    username,
    newRole: 'crew'
  }, {
    withCredentials: true
  });
  return response.data;
};

export const degradeGangMember = async (gangId, username) => {
  const response = await axios.post(`${API_URL}/gangs/${gangId}/degrade-member`, {  // Changed endpoint
    username,
    newRole: 'member'
  }, {
    withCredentials: true
  });
  return response.data;
};

export const fetchUserJoinRequests = async () => {
  const { userId } = useGangsStore.getState(); // Access store state for userId

  if (!userId) {
    // If userId is not defined, we log an error and prevent the fetch
    console.error('User ID is required to fetch join requests');
    throw new Error('User ID is required to fetch join requests');
  }

  try {
    // Fetch join requests using userId in the URL path
    const response = await axios.get(`${API_URL}/users/${userId}/join-requests`);

    // Check if the response was successful
    if (response.data.success) {
      return response.data.joinRequests;
    } else {
      throw new Error('Failed to fetch join requests');
    }
  } catch (error) {
    console.error('Error fetching join requests:', error);
    throw new Error('Failed to fetch join requests');
  }
};

export const deleteJoinRequest = async (userId, gangId) => {
  try {
    const response = await axios.post(`${API_URL}/users/${userId}/delete-join-request`, { gangId });
    return response.data; // Assuming the response will contain a message or status
  } catch (error) {
    console.error('Error deleting join request:', error);
    throw new Error('Failed to delete join request');
  }
};