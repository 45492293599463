import React, { useEffect } from 'react';
import useDrugsStore from '../store/drugsStore';  // Zustand store
import { fetchUserId, fetchUserDrugs, sellDrug } from '../services/drugsService';  // API services
import { t } from 'i18next';

const Drugs = () => {
  const {
    userDrugs,
    sellQuantity,
    message,
    userId,
    setUserDrugs,
    setSellQuantity,
    setMessage,
    setUserId,
    updateSellQuantity,
  } = useDrugsStore();  // Access Zustand state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = await fetchUserId();
        setUserId(userId);
        const userDrugsData = await fetchUserDrugs(userId);
        setUserDrugs(userDrugsData);
      } catch (error) {
        console.error('Error fetching user ID or drugs:', error);
        setMessage('Error fetching user drugs');
      }
    };

    fetchData();
  }, [setUserId, setUserDrugs, setMessage]);

  const handleQuantityChange = (drugId, quantity) => {
    updateSellQuantity(drugId, quantity);
  };

  const handleSell = async (drugId, price) => {
    const quantity = parseInt(sellQuantity[drugId]) || 0;

    if (quantity <= 0 || quantity > (userDrugs.find(drug => drug.item_id === drugId)?.quantity || 0)) {
      setMessage('Invalid quantity');
      return;
    }

    try {
      const response = await sellDrug(userId, drugId, quantity, price);
      setMessage(response.message);

      // Refresh user's drug data after selling
      const userDrugsData = await fetchUserDrugs(userId);
      setUserDrugs(userDrugsData);
    } catch (err) {
      console.error('Error selling drug:', err);
      setMessage('Failed to sell drug');
    }
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center mobile-friendly"
    >

      <div
        className="armors-container bg-black bg-opacity-60 p-8 rounded-lg text-left shadow-lg max-w-lg w-full mb-20 mt-20"
        style={{
          transform: 'translateY(0px)', // Move the container 50px closer to the top
        }}
      >
        <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Drugs')}</h2>

        <div className="text-white">
          <p dangerouslySetInnerHTML={{ __html: t('SellGoodsDesc') }}></p>
          <br />
        </div>

        <h3 className="text-2xl font-semibold mb-4 text-yellow-500">{t('YourDrugs')}</h3>
        {message && <p className="text-red-500">{message}</p>}
        {userDrugs.length === 0 ? (
          <p className='text-white'>{t('NoDrugs')}</p>
        ) : (
          <ul className="mb-6">
            {userDrugs.map((drug) => (
              <li key={drug.item_id} className="mb-4 text-white flex justify-between items-center">
                <div>
                  <p>{drug.drug_name} - Base Price: ${drug.base_price}</p>
                  <p>You own: {drug.quantity} units</p>
                  <input
                    type="number"
                    placeholder="Quantity to sell"
                    value={sellQuantity[drug.item_id] || ''}  // Track the input for each drug
                    onChange={(e) => handleQuantityChange(drug.item_id, e.target.value)}
                  />

                  <button
                    className="ml-4 bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300"
                    onClick={() => handleSell(drug.item_id, parseFloat(drug.base_price))}>{t('Sell')}</button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Drugs;
