// frontend/src/store/weaponsStore.js
import { create } from 'zustand';

const useWeaponsStore = create((set) => ({
  weaponsItems: [],
  balance: 0,
  userItems: [],
  message: '',
  userId: null,

  setWeaponsItems: (items) => set({ weaponsItems: items }),
  setBalance: (balance) => set({ balance }),
  setUserItems: (items) => set({ userItems: items }),
  setMessage: (message) => set({ message }),
  setUserId: (userId) => set({ userId }),
}));

export default useWeaponsStore;
