let eventSource = null;

export const initializeSSE = (onMessageCallback) => {
    if (eventSource) {
        console.warn('SSE connection is already established.');
        return;
    }

    const API_URL = process.env.REACT_APP_API_URL;
    eventSource = new EventSource(`${API_URL}/sse`, { withCredentials: true });

    eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        onMessageCallback(data);
    };

    eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);
        closeSSE();
    };

    eventSource.onopen = () => {
        console.log('SSE connection established.');
    };
};

export const closeSSE = () => {
    if (eventSource) {
        eventSource.close();
        eventSource = null;
    }
};
