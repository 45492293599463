import React, { useState, useEffect } from 'react';
import formatCountdown from './formatCountdown';

function CountdownDisplay() {
    const [nextRefillCountdown, setNextRefillCountdown] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/countdown');
                const data = await response.json();
                setNextRefillCountdown(data.nextRefillCountdown);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 1000); // fetch data every 1 second

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div>
            <p>
                <span className="font-bold">Next Refill In:</span>
                <span>{formatCountdown(nextRefillCountdown)}</span>
            </p>
        </div>
    );
}

export default CountdownDisplay;