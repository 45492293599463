import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || 'https://your-supabase-url.supabase.co'; // Your Supabase URL
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY || 'your-anon-key'; // Your Supabase Anon Key

const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);

// Fetch recent messages
const fetchMessages = async () => {
    try {
        const { data, error } = await supabase
            .from('chat_messages')
            .select('*')
            .order('timestamp', { ascending: false })
            .limit(50);  // Adjust limit if necessary

        if (error) {
            console.error('Error fetching messages:', error);
            return { success: false, message: 'Failed to fetch messages' };
        }

        return { success: true, messages: data.reverse() }; // Reversing for chronological order
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Internal server error' };
    }
};

// Save a new message
const sendMessage = async (username, message, user_id) => {
    if (!username || !message || !user_id) {
        return { success: false, message: 'Invalid message data' };
    }

    try {
        const { data, error } = await supabase
            .from('chat_messages')
            .insert([{ username, message, user_id }])
            .select();  // Returning the inserted data

        if (error) {
            console.error('Error saving message:', error);
            return { success: false, message: 'Failed to send message' };
        }

        return { success: true, message: data[0] };
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Internal server error' };
    }
};

export default { fetchMessages, sendMessage };
