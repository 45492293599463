import React from 'react';

const Footer = () => {
    return (
        <footer className="bottom-0 bg-black text-gray-400 py-4 border-t-4 border-yellow-500 mobile-footer">
            <div className="container mx-auto text-center">
                <p className="mb-2">
                    &copy; {new Date().getFullYear()} Gangbangers. All rights reserved.
                </p>
                <div className="flex justify-center space-x-6">
                    <a href="/privacy" className="hover:text-yellow-500 transition duration-300">
                        Privacy Policy
                    </a>
                    <a href="/terms" className="hover:text-yellow-500 transition duration-300">
                        Terms of Service
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
