import React, { useEffect, useState } from 'react';
import { fetchMembersCount } from '../../services/usersService';
import useAuthStore from '../../store/authStore';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

function Home() {
  const { t } = useTranslation();
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const { isAuthenticated, checkAuthStatus, loading } = useAuthStore();

  const limaImagePath = '/lima.jpg';

  useEffect(() => {
    const initializePage = async () => {
      await checkAuthStatus();

      if (isAuthenticated) {
        setShowImage(true);
      } else {
        setShowImage(false);
        fetchUserCount();
      }
    };

    initializePage();
  }, [isAuthenticated, checkAuthStatus]);

  const fetchUserCount = async () => {
    try {
      // Attempt to fetch members count
      const data = await fetchMembersCount();
      setRegisteredUsers(data);
    } catch (error) {
      // Log error details, including response
      if (error.response) {
        console.error('Error fetching members count (response):', {
          status: error.response.status,
          data: error.response.data,
        });
      } else {
        console.error('Error fetching members count (network or other):', error.message);
      }
      // Optional: Set registeredUsers to an empty array to avoid UI issues
      setRegisteredUsers([]);
    }
  };


  // Show loading indicator while checking authentication status
  if (loading) {
    return <div>Loading...</div>;
  }

  // Display content for authenticated users
  if (isAuthenticated) {
    const kissSound = new Audio('/kiss2.wav');
    kissSound.volume = 0.3;
    setTimeout(() => {
      //kissSound.play();
    }, 8000);
    
    return (
      <div className="flex flex-col min-h-screen justify-center items-center">
        {showImage && <img src={limaImagePath} alt="Lima" className="m-auto mt-4" />}
      </div>
    );
  }
  
  // Display content for unauthenticated users
  return (
    <div className="flex flex-col min-h-screen">
      <div
        className="flex-grow bg-cover bg-center"
      >
        <div className="container mx-auto p-4 pt-6 md:p-6 lg:p-12 xl:p-15 flex justify-center items-center">
          <div className="bg-white bg-opacity-50 rounded-lg p-8 text-center shadow-lg">
            <h1 className="text-5xl font-bold mb-4 text-yellow-600">{t('title')}</h1>
            <p className="text-2xl font-bold mb-4 text-yellow-600">{t('subtitle')}</p>
            <div className="text-lg leading-relaxed mb-4">
              <p>{t('intro')}</p>
              <p>{t('intro_paragraph_1')}</p>
              <p>{t('intro_paragraph_2')}</p>
            </div>
            <div className="text-lg leading-relaxed mb-4">
              <p>{t('gameplay_paragraph_1')}</p>
              <p>{t('gameplay_paragraph_2')}</p>
              <p>{t('gameplay_paragraph_3')}</p>
            </div>
            <div className="bg-yellow-500 text-white font-black text-center p-2 rounded mb-4">
              <p>{t('game_modes_header')}</p>
              <p>{t('game_mode_1')}</p>
              <p>{t('game_mode_2')}</p>
              <p>{t('game_mode_3')}</p>
            </div>
            <div>
              {t('registered_users')}: {registeredUsers.length}
            </div>
            <br />
            <a
              className="bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded shadow-md"
              href="/register"
            >
              {t('join_button')}
            </a>
          </div>
        </div>
        <div className="bg-gray-500 text-white font-black text-center p-2 rounded mt-4">
          <p dangerouslySetInnerHTML={{ __html: t('prize_announcement') }}></p>
        </div>
      </div>
    </div>
  );
}

export default Home;