import React, { useEffect } from 'react';
import GangDetails from './GangDetails';
import AvailableGangs from './AvailableGangs';
import useGangsStore from '../../store/gangsStore';
import { fetchUserId, fetchUserGang } from '../../services/gangsService';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

const Gangs = () => {
  const { t } = useTranslation();
  const { userGang, setUserId, setUserGang, setGangs, setLoading, setMessage } = useGangsStore();

  useEffect(() => {
    const fetchData = async () => {
      setMessage('');
      try {
        const { userId } = await fetchUserId();
        setUserId(userId);

        const userGangData = await fetchUserGang(userId);

        if (userGangData && typeof userGangData.id !== 'undefined') {
          setUserGang(userGangData);
          setMessage(''); // Clear any previous messages
        } else if (userGangData && Array.isArray(userGangData.gangs)) {
          setGangs(userGangData.gangs);
          setUserGang(null);
          if (userGangData.gangs.length > 0) {
            setMessage('You are not in a gang. Check out the available gangs.');
          } else {
            setMessage('No gangs created yet!');
          }
        } else {
          setUserGang(null);
          setGangs([]);
          setMessage('No gang or available gangs found.');
        }
      } catch (error) {
        setUserGang(null);
        setGangs([]);
        setMessage('Error fetching user information!!!');
      } finally {
        setLoading(false);  // Important to stop loading
      }
    };

    fetchData();
  }, [setUserId, setUserGang, setGangs, setLoading, setMessage]);

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center"
    >
      <div className="bg-black bg-opacity-60 p-6 rounded-lg shadow-lg max-w-lg w-full mb-20 mt-20">
        <h2 className="text-3xl font-bold mb-2 text-yellow-500 text-left">{t('GangsTitle')}</h2>
        <div className="w-full">
          {userGang ? <GangDetails /> : <AvailableGangs />}
        </div>
      </div>
    </div>
  );

};

export default Gangs;
