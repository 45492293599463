//// frontend/src/services/membersService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchMembersCount = async () => {
    try {
        const response = await axios.get(`${API_URL}/members`);
        return response.data;
    } catch (error) {
        console.error('API error:', error.response || error.message); // Log errors clearly
        throw error; // Ensure error is thrown only when necessary
    }
};


export const checkUserPremium = async (userId) => {
    const response = await axios.get(`${API_URL}/premium-check/${userId}`);
    return response.data;
};

export const fetchEquipment = async (userId) => {
    const response = await axios.get(`${API_URL}/equipment/${userId}`, {
        withCredentials: true,
    });
    return response.data;
};
