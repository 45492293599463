// frontend/src/services/bankService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserIdAndBalance = async () => {
  const response = await axios.get(`${API_URL}/stats`, {
    withCredentials: true,
  });
  return response.data;
};

export const depositMoney = async (userId, amount) => {
  const response = await axios.post(
    `${API_URL}/bank/deposit`,
    { userId, amount },
    { withCredentials: true }
  );
  return response.data;
};

export const withdrawMoney = async (userId, amount) => {
  const response = await axios.post(
    `${API_URL}/bank/withdraw`,
    { userId, amount },
    { withCredentials: true }
  );
  return response.data;
};

export const fetchBankBalance = async (userId) => {
  const response = await axios.get(
    `${API_URL}/bank/${userId}`,
    { withCredentials: true }
  );
  return response.data;
};
