import React, { useState, useEffect, useRef } from 'react';
import chatService from '../../services/chatService'; // Adjust the path to your chatService
import useAuthStore from '../../store/authStore'; // Adjust the path to your auth store
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n'; // Adjust the path to your i18n configuration

const Chat = () => {
    const [inputMessage, setInputMessage] = useState('');
    const [messages, setMessages] = useState([]); // Local state for messages
    const [loading, setLoading] = useState(false); // Local loading state for message sending
    const { user, isAuthenticated } = useAuthStore();
    const messagesEndRef = useRef(null);
    const { t } = useTranslation();

    // Fetch messages on load
    useEffect(() => {
        const loadMessages = async () => {
            const fetchedMessages = await chatService.fetchMessages();
            // Ensure the fetchedMessages are an array (if undefined, default to [])
            setMessages(fetchedMessages?.messages || []);
        };

        loadMessages();
    }, []);

    // Scroll to the bottom of the chat when messages change
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSendMessage = async (e) => {
        e.preventDefault();

        if (inputMessage.trim() && user) {
            setLoading(true); // Start loading when sending a message
            const newMessage = await chatService.sendMessage(user.username, inputMessage.trim(), user.id);
            if (newMessage.success) {
                setMessages((prevMessages) => [...prevMessages, newMessage.message]); // Append new message
            }
            setInputMessage('');
            setLoading(false); // Stop loading after message is sent
        }
    };

    if (!isAuthenticated || !user) {
        return <div className="text-center p-4">{t('PleaseLoginToChat')}</div>;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto px-4 py-8">
                <div className="w-[150vh] h-[150vh] mx-auto bg-gray-300 rounded-lg shadow-lg">
                    {/* Chat Header */}
                    <div className="bg-black p-6 rounded-t-lg">
                        <h1 className="text-3xl font-bold text-white">{t('GangBangersSocialize')}</h1>
                    </div>

                    {/* Messages Section */}
                    <div className="h-[130vh] overflow-y-auto p-6">
                        {messages && messages.length > 0 ? (
                            messages.map((msg) => (
                                <div
                                    key={msg.id}
                                    className={`flex items-start ${msg.username === user.username ? 'justify-end' : ''} mb-3`}
                                >
                                    <div
                                        className={`p-4 rounded-lg max-w-[70%] break-words ${msg.username === user.username
                                            ? 'bg-yellow-500 text-white'
                                            : 'bg-white text-black'
                                            }`}
                                    >
                                        <div className="flex items-center mb-1">
                                            <span className="font-bold mr-2">{msg.username}</span>
                                            <span className="text-xs text-gray-500">
                                                {new Date(msg.timestamp).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })}
                                            </span>
                                        </div>
                                        <p>{msg.message}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center text-gray-500">{t('NoMessages')}</div>
                        )}
                        <div ref={messagesEndRef}></div>
                    </div>

                    {/* Input Section */}
                    <form onSubmit={handleSendMessage} className="p-6 bg-white border-t rounded-b-lg">
                        <div className="flex gap-4">
                            <input
                                type="text"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                placeholder={t('TypeYourMessage')}
                                className="flex-1 bg-gray-200 p-2 rounded-lg"
                            />
                            <button
                                type="submit"
                                className="bg-blue-500 text-white p-3 rounded-lg disabled:opacity-50"
                                disabled={loading || !inputMessage.trim()}
                            >
                                {loading ? t('Sending...') : t('Send')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Chat;
