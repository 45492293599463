import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchRequirements = async (robberyType) => {
  try {
    const response = await axios.get(`${API_URL}/gang-robbery/requirements/${robberyType}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching requirements:', error);
    throw error;
  }
};

export const fetchCurrentRobbery = async (gangId) => {
  try {
    const response = await axios.get(`${API_URL}/gang-robbery/current/${gangId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching current robbery:', error);
    throw error;
  }
};

export const planGangRobbery = async (robberyType, gangId) => {
  try {
    const response = await axios.post(`${API_URL}/gang-robbery/plan`, {
      robberyType,
      gangId
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Plan robbery error:', error);
    throw error;
  }
};

export const acceptRobbery = async (gangId) => {
  try {
    const response = await axios.post(`${API_URL}/gang-robbery/accept`, {
      gangId
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Accept robbery error:', error);
    throw error;
  }
};

export const executeGangRobbery = async (gangId) => {
  try {
    const response = await axios.post(`${API_URL}/gang-robbery/execute`, {
      gangId
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Execute robbery error:', error);
    throw error;
  }
};

export const cancelRobbery = async (gangId) => {
  try {
      const response = await axios.post(`${API_URL}/gang-robbery/cancel`, {
          gangId
      }, {
          withCredentials: true
      });
      return response.data;
  } catch (error) {
      console.error('Cancel robbery error:', error);
      throw error;
  }
};

