import React, { useEffect, useState } from 'react';
import useArmoryStore from '../store/armoryStore';
import {
  fetchUserId,
  fetchArmoryItems,
  fetchUserItems,
  buyItem,
  sellItem,
} from '../services/armoryService';
import { checkUserPremium } from '../services/usersService';
import useAuthStore from '../store/authStore';
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

const Armory = () => {
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const [isUserPremium, setIsUserPremium] = useState(false);

  const {
    armoryItems,
    balance,
    userItems,
    message,
    userId,
    setArmoryItems,
    setBalance,
    setUserItems,
    setMessage,
    setUserId,
  } = useArmoryStore();  // Access Zustand state

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user ID and balance
        const { userId, money } = await fetchUserId();
        setUserId(userId);
        setBalance(money);

        // Fetch armory items and user items
        const armoryData = await fetchArmoryItems();
        setArmoryItems(armoryData);

        const userItemsData = await fetchUserItems(userId);
        setUserItems(userItemsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [setUserId, setBalance, setArmoryItems, setUserItems]);

  useEffect(() => {
    const checkPremiumStatus = async () => {
      if (user && user.id) {
        try {
          const premiumData = await checkUserPremium(user.id);
          setIsUserPremium(premiumData.premium);
        } catch (error) {
          console.error('Error checking premium status:', error);
        }
      }
    };
    checkPremiumStatus();
  }, [user]);

  const handleBuy = async (itemId) => {
    try {
      const item = armoryItems.find(item => item.id === itemId);
      if (item && item.name === 'Armored Car' && !isUserPremium) {
        setMessage('Only VIP members can purchase the Armored Car.');
        return;
      }

      const response = await buyItem(userId, itemId);
      setMessage(response.message);

      // Update balance after purchase
      const { money } = await fetchUserId();
      setBalance(money);

      const userItemsData = await fetchUserItems(userId);
      setUserItems(userItemsData);
    } catch (error) {
      console.error('Error buying item:', error);
      setMessage('Failed to buy item');
    }
  };

  const handleSell = async (itemId) => {
    try {
      const response = await sellItem(userId, itemId);
      setMessage(response.message);

      // Update balance after selling
      const { money } = await fetchUserId();
      setBalance(money);

      const userItemsData = await fetchUserItems(userId);
      setUserItems(userItemsData);
    } catch (error) {
      console.error('Error selling item:', error);
      setMessage('Failed to sell item');
    }
  };

  if (!armoryItems.length) {
    return <p>Loading armory items...</p>;
  }

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center rounded-lg mobile-friendly"
    >
      <div
        className="armory-container bg-black bg-opacity-60 p-8 rounded-lg text-left shadow-lg max-w-lg w-full mb-20 mt-20"
        style={{
          transform: 'translateY(0px)', // Move the container 50px closer to the top
        }}
      >
        <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Armory')}</h2>

        <div className="text-white">
          <p>
            <p dangerouslySetInnerHTML={{ __html: t('ArmoryDesc') }} />
          </p>
          <br />
          <p className="text-red-600 font-bold" dangerouslySetInnerHTML={{ __html: t('ArmoryDesc2') }} />
          <br />
        </div>

        <p className="text-yellow-500">{t('Balance')}: {balance}</p>
        {message && <p className="text-red-500">{message}</p>}

        <ul className="mb-6">
          {armoryItems.map((item) => (
            <li key={item.id} className="mb-4 text-white flex items-start justify-between border-b border-gray-700 pb-4">
              {/* Item Name with fixed width */}
              <span className="font-bold text-yellow-400 text-lg w-1/3">{t(item.name)}</span>

              {/* Item Details */}
              <div className="text-gray-300 text-sm w-1/3">
                <p><span className="text-yellow-500 font-semibold">Price:</span> {item.price}</p>
                <p><span className="text-yellow-500 font-semibold">Defense:</span> {item.defense}</p>
                <p><span className="text-yellow-500 font-semibold">Attack:</span> {item.damage}</p>
              </div>

              {/* Button */}
              {item.name === 'Armored Car' && !isUserPremium ? (
                <button
                  className="ml-4 bg-gray-500 text-white px-4 py-2 rounded cursor-not-allowed"
                  disabled
                >
                  VIP Only
                </button>
              ) : (
                <button
                  className="ml-4 bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-600 transition duration-300"
                  onClick={() => handleBuy(item.id)}
                >
                  {t('Buy')}
                </button>
              )}
            </li>
          ))}
        </ul>



        <h3 className="text-2xl font-semibold mb-4 text-yellow-500">Your Items</h3>
        {userItems.length === 0 ? (
          <p className='text-white'>{t('NoItems')}</p>
        ) : (
          <ul>
              {userItems.map((item) => {
                const matchingArmor = armoryItems.find((armor) => armor.id === item.item_id); // Match with armoryItems
                const sellPrice = matchingArmor? matchingArmor.price / 2 : 'N/A'; // Calculate sell price or fallback to 'N/A'

                return (
                  <li key={item.item_id} className="mb-4 text-white flex justify-between items-center">
                    <span>{t(item.item_name)} - Quantity: {item.quantity} - Sell Price: {sellPrice}</span>
                    <button
                      className="ml-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                      onClick={() => handleSell(item.item_id)}
                    >
                      {t('Sell')}
                    </button>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Armory;
