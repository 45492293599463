import React from 'react';

const PremiumModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            {/* Increased width to 1200px */}
            <div className="bg-white rounded-lg p-6 w-[1200px] mx-4 overflow-auto">
                <h2 className="text-2xl font-bold mb-4 text-center">Buy Premium</h2>
                <p className="text-gray-700 mb-6 text-center">
                    Choose a premium package to unlock exclusive features:
                </p>

                {/* Premium Packages Container */}
                <div className="flex justify-between space-x-8 mb-6">
                    {/* Premium 1 */}
                    <div className="p-4 border-2 border-gray-300 rounded-lg text-center flex flex-col w-full">
                        <h3 className="text-lg font-semibold mb-2">Premium 1</h3>
                        <ul className="list-disc list-inside text-gray-700 mb-4 flex-grow">
                            <p>Buildings production increased by +10%</p>
                        </ul>
                        <div className="flex justify-center">
                            <button className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400">
                                Buy Premium 1 - $10
                            </button>
                        </div>
                    </div>

                    {/* Premium 2 */}
                    <div className="p-4 border-2 border-gray-300 rounded-lg text-center flex flex-col w-full">
                        <h3 className="text-lg font-semibold mb-2">Premium 2</h3>
                        <ul className="list-disc list-inside text-gray-700 mb-4 flex-grow">
                            <p>Buildings production increased by +20%</p><br/>
                            <p>Access to premium-only weapons and armors</p>
                        </ul>
                        <div className="flex justify-center">
                            <button className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400">
                                Buy Premium 2 - $30
                            </button>
                        </div>
                    </div>

                    {/* Premium 3 */}
                    <div className="p-4 border-2 border-gray-300 rounded-lg text-center flex flex-col w-full">
                        <h3 className="text-lg font-semibold mb-2">Premium 3</h3>
                        <ul className="list-disc list-inside text-gray-700 mb-4 flex-grow">
                            <p>Buildings production increased by +20%</p><br/>
                            <p>Access to premium-only weapons and armors</p><br/>
                            <p>Drugs can be sold for +10% more money</p><br/>
                            <p>Bank interest increased by +10%</p>
                        </ul>
                        <div className="flex justify-center">
                            <button className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400">
                                Buy Premium 3 - $50
                            </button>
                        </div>
                    </div>
                </div>

                {/* Close Button */}
                <div className="flex justify-center mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-400"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PremiumModal;
