import React, { useEffect } from 'react';
import useBankStore from '../store/bankStore';  // Zustand store
import {
  fetchUserIdAndBalance,
  fetchBankBalance,
  depositMoney,
  withdrawMoney
} from '../services/bankService';  // API services
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

const Bank = () => {
  const { t } = useTranslation();
  const {
    bankBalance,
    walletBalance,
    userId,
    message,
    amount,
    setBankBalance,
    setWalletBalance,
    setUserId,
    setMessage,
    setAmount,
    clearAll,
  } = useBankStore();  // Access Zustand state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userId, money } = await fetchUserIdAndBalance();
        setUserId(userId);
        setWalletBalance(money);

        const bankData = await fetchBankBalance(userId);
        setBankBalance(bankData.bank_balance);
        setWalletBalance(bankData.wallet_balance);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    clearAll();
    fetchData();

    return () => {
      clearAll();
    };

  }, [setUserId, setWalletBalance, setBankBalance, clearAll]);

  const handleDeposit = async () => {
    if (amount <= 0) {
      setMessage('Deposit amount must be greater than zero');
      return;
    }

    try {
      const response = await depositMoney(userId, amount);
      setMessage(response.message);

      const { bank_balance, wallet_balance } = await fetchBankBalance(userId);
      setBankBalance(bank_balance);
      setWalletBalance(wallet_balance);
    } catch (error) {
      console.error('Error depositing money:', error);
      setMessage('Failed to deposit money');
    }
  };

  const handleWithdraw = async () => {
    if (amount <= 0) {
      setMessage('Withdraw amount must be greater than zero');
      return;
    }

    try {
      const response = await withdrawMoney(userId, amount);
      setMessage(response.message);

      const { bank_balance, wallet_balance } = await fetchBankBalance(userId);
      setBankBalance(bank_balance);
      setWalletBalance(wallet_balance);
    } catch (error) {
      console.error('Error withdrawing money:', error);
      setMessage('Failed to withdraw money');
    }
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center mobile-friendly"
    >

      <div
        className="bank-container bg-black bg-opacity-60 p-8 rounded-lg text-left shadow-lg max-w-lg w-full mb-20 mt-20"
        style={{
          transform: 'translateY(0px)', // Move the container 50px closer to the top
        }}
      >
        <div>
          <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Bank')}</h2>
          <p className="text-white">{t('BankDesc1')}</p>
          <p className="text-white">{t('BankDesc2')}</p><br />
          <p className="text-white">{t('BankInterest1')}</p>
          <p className="text-white">{t('BankInterest2')}</p>
          <p className="text-white">{t('BankInterest3')}</p><br />
          <p className="text-red-600 font-bold">{t('BankPremiumNote')}</p><br />
          <p className="text-yellow-500">{t('BankBalance')}: {bankBalance}</p><br />
          <p className="text-yellow-500">{t('WalletBalance')}: {walletBalance}</p><br />

          {message && <p className="text-red-500">{message}</p>}

          <input
            className="mb-6"
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="1"
          />
          <div>
            <button className="ml-4 bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300" onClick={handleDeposit}>{t('Deposit')}</button>
            <button className="ml-4 bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300" onClick={handleWithdraw}>{t('Withdraw')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bank;
