// frontend/src/services/robberyService.js

const API_URL = process.env.REACT_APP_API_URL;

export const attemptRobbery = async (robberyType) => {
  const response = await fetch(`${API_URL}/robbery`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ robberyType }),
  });

  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message);
  }

  return response.json();
};

export const getRobberyRequirements = async (robberyType) => {
  const response = await fetch(`${API_URL}/robbery/requirements/${robberyType}`, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!response.ok) {
    const err = await response.json();
    throw new Error(err.message || 'Failed to fetch requirements');
  }

  return response.json();
};