// frontend/src/store/drugsStore.js
import { create } from 'zustand';

const useDrugsStore = create((set) => ({
  userDrugs: [],
  sellQuantity: {},
  message: '',
  userId: null,

  setUserDrugs: (userDrugs) => set({ userDrugs }),
  setSellQuantity: (sellQuantity) => set({ sellQuantity }),
  setMessage: (message) => set({ message }),
  setUserId: (userId) => set({ userId }),
  updateSellQuantity: (drugId, quantity) =>
    set((state) => ({
      sellQuantity: {
        ...state.sellQuantity,
        [drugId]: quantity,
      },
    })),
}));

export default useDrugsStore;
