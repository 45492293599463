// frontend/src/store/armoryStore.js
import { create } from 'zustand';

const useArmoryStore = create((set) => ({
  armoryItems: [],
  balance: 0,
  userItems: [],
  message: '',
  userId: null,
  
  setArmoryItems: (items) => set({ armoryItems: items }),
  setBalance: (balance) => set({ balance }),
  setUserItems: (items) => set({ userItems: items }),
  setMessage: (message) => set({ message }),
  setUserId: (id) => set({ userId: id }),
}));

export default useArmoryStore;
