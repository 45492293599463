// frontend/src/store/dashboardStore.js
import { create } from 'zustand';
import { fetchDashboardMessage, fetchPlayerStats } from '../services/dashboardService';

const useDashboardStore = create((set) => ({
  message: '',
  stats: null,
  error: null,
  currentStamina: null,  // Initialize as `null` until fetched
  maxStamina: null,      // Initialize as `null` until fetched

  // Setters
  setMessage: (message) => set({ message }),
  setStats: (stats) => set({
    stats,
    currentStamina: stats.current_stamina,
    maxStamina: stats.max_stamina,
  }),
  setError: (error) => set({ error }),

  // Method to fetch player stats and stamina from the server
  fetchPlayerStats: async () => {
    try {
      const response = await fetchPlayerStats();
      set({
        stats: response,
        currentStamina: response.current_stamina,
        maxStamina: response.max_stamina,
      });
    } catch (error) {
      set({ error: 'Error fetching player stats' });
    }
  },

  // Method to fetch dashboard message from the server
  fetchDashboardMessage: async () => {
    try {
      const response = await fetchDashboardMessage();
      set({ message: response });
    } catch (error) {
      set({ error: 'Error fetching dashboard message' });
    }
  },
}));

export default useDashboardStore;
