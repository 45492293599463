import React, { useEffect, useState } from 'react';
import useGangsStore from '../../store/gangsStore';
import {
  fetchUserGang,
  fetchGangMembers,
  fetchGangJoinRequests,
  sendJoinRequest,
  acceptJoinRequest,
  declineJoinRequest,
  promoteGangMember,
  degradeGangMember,
  fireGangMembers,
  disbandGang,
  quitGang,
} from '../../services/gangsService';
import { t } from 'i18next';

const GangDetails = () => {
  const { userId, setMessage, setGangJoinRequests } = useGangsStore();
  const [userGang, setUserGang] = useState(null); // Basic gang details
  const [members, setMembers] = useState([]);     // Detailed member data
  const [joinRequests, setJoinRequests] = useState([]); // State for join requests

  useEffect(() => {
    const loadGangData = async () => {
      try {
        // First, fetch basic gang details
        const gangData = await fetchUserGang(userId);
        setUserGang(gangData);

        // Then, fetch members if gang exists
        if (gangData?.id) {
          const membersData = await fetchGangMembers(gangData.id);
          setMembers(membersData.members); // Assuming members is an array in the response

          // Fetch join requests for the gang
          const requestsData = await fetchGangJoinRequests(gangData.id);
          setJoinRequests(requestsData); // Use joinRequests state variable
          setGangJoinRequests(requestsData);
        }
      } catch (error) {
        console.error('Error loading gang data:', error);
      }
    };

    loadGangData();
  }, [userId, setGangJoinRequests]);

  const handlePromoteMember = async (gangId, username) => {
    try {
      console.log('Promoting member:', { gangId, username }); // Debug log
      await promoteGangMember(gangId, username);
      setMessage('Member promoted successfully.');

      // Refresh the members list
      const membersData = await fetchGangMembers(gangId);
      setMembers(membersData.members);
    } catch (error) {
      console.error('Error promoting member:', error.response?.data || error);
      setMessage('Failed to promote member.');
    }
  };

  const handleDegradeMember = async (gangId, username) => {
    try {
      console.log('Degrading member:', { gangId, username }); // Debug log
      await degradeGangMember(gangId, username);
      setMessage('Member degraded successfully.');

      // Refresh the members list
      const membersData = await fetchGangMembers(gangId);
      setMembers(membersData.members);
    } catch (error) {
      setMessage('Failed to degrade member.');
    }
  };


  const handleDeclineRequest = async (username) => {
    try {
      await declineJoinRequest(userGang.id, username); // Call decline function
      setMessage('Join request declined successfully.');

      // Update join requests in local state
      const updatedJoinRequests = await fetchGangJoinRequests(userGang.id);
      setJoinRequests(updatedJoinRequests);
      setGangJoinRequests(updatedJoinRequests); // Update global state
    } catch (error) {
      console.error('Error declining join request:', error);
      setMessage('Failed to decline join request.');
    }
  };

  const handleAcceptRequest = async (username) => {
    try {
      await acceptJoinRequest(userGang.id, username); // Call accept function
      setMessage('Join request accepted successfully.');

      const updatedJoinRequests = await fetchGangJoinRequests(userGang.id);
      setJoinRequests(updatedJoinRequests);
      setGangJoinRequests(updatedJoinRequests);

      window.location.reload();
    } catch (error) {
      console.error('Error accepting join request:', error);
      setMessage('Failed to accept join request.');
    }
  };

  const handleDisbandGang = () => {
    console.log('Disbanding gang with ID:', userGang.id);
    disbandGang(userGang.id);
  };

  const handleQuitRequest = async (userId, gangId) => {
    try {
      await quitGang(userId, gangId);
      setMessage('Quit request sent successfully.');
      window.location.reload();
    } catch (error) {
      console.error('Error quitting gang:', error);
      setMessage('Failed to send quit request.');
    }
  };

  const handleFireRequest = async (gangId, username) => {
    if (!gangId || !username) {
      setMessage('Cannot fire member: Missing required information');
      return;
    }

    try {
      console.log('Firing member with:', { gangId, username }); // Debug log
      const result = await fireGangMembers(gangId, username);
      console.log('Fire result:', result); // Debug log

      setMessage(`Successfully fired ${username}`);

      // Refresh the members list
      const membersData = await fetchGangMembers(gangId);
      if (membersData && membersData.members) {
        setMembers(membersData.members);
      } else {
        setMembers([]); // Set empty array if no members returned
      }
    } catch (error) {
      console.error('Fire request failed:', error);
      setMessage(`Failed to fire ${username}: ${error.response?.data?.message || error.message}`);
    }
  };


  return (
    <div className="bg-black bg-opacity-60 p-6 rounded-lg text-left shadow-lg min-w-[400px] mb-20 mt-20">
      <h3 className="text-3xl font-bold mb-4 text-yellow-500">{t('YourGang')}</h3>
      {userGang ? (
        <>
          <p className="text-white">{t('GangName')}: <span className="text-gray-400">{userGang.name}</span></p>
          <p className="text-white">{t('GangLeader')}: <span className="text-gray-400">{userGang.leader_name}</span></p>
          <p className="text-white">{t('GangMembersCount')}: <span className="text-gray-400">{userGang.members_count}</span></p>

          <div className="mt-4">
            <b className="text-yellow-500">{t('Members')}:</b>
            <div>
              {members.length > 0 ? (
                members.map((member, index) => (
                  <div key={index} className="text-gray-400 flex justify-between items-center mb-2">
                    <span>{member.username || 'Unnamed Member'} ({member.role || 'No Role'})</span>
                    {userGang.leader_id === userId && member.role !== 'leader' && (
                      <>
                        <button
                          onClick={() => handleFireRequest(userGang.id, member.username)}
                          className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300 ml-2"
                        >
                          {t('Fire')}
                        </button>
                        <button
                          onClick={() => handlePromoteMember(userGang.id, member.username)}
                          className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 transition duration-300 ml-2"
                        >
                          {t('PromoteToCrew')}
                        </button>
                        <button
                          onClick={() => handleDegradeMember(userGang.id, member.username)}
                          className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 transition duration-300 ml-2"
                        >
                          {t('DegradeToMember')}
                        </button>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-400">{t('NoMembers')}</p>
              )}
            </div>
          </div>

          {userGang.leader_id === userId && (
            <div className="mt-4">
              <p className="text-yellow-500"><u>{t('JoinRequests')}:</u></p>
              {joinRequests.length > 0 ? (
                joinRequests.map((username, index) => (
                  <div key={index} className="flex justify-between items-center text-gray-400 mb-2">
                    <span>{username}</span>
                    <button
                      onClick={() => handleAcceptRequest(username)}
                      className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition duration-300 ml-2"
                    >
                      {t('Accept')}
                    </button>
                    <button
                      onClick={() => handleDeclineRequest(username)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300 ml-2"
                    >
                      {t('Decline')}
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-400">{t('NoJoinRequests')}</p>
              )}
              <button
                onClick={() => {
                  handleDisbandGang();
                  window.location.reload();  // Refresh the page after disbanding
                }}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 mt-4"
              >
                {t('DisbandGang')}
              </button>
            </div>
          )}
          {/* Quit Gang button for non-leader members */}
          {userGang.leader_id !== userId && (
            <button
              onClick={() => handleQuitRequest(userId, userGang.id)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 mt-4 w-full"
            >
              {t('QuitGang')}
            </button>
          )}
          {/* Quit Gang Ended */}
        </>
      ) : (
        <p className="text-gray-400">{t('LoadingGangData')}</p>
      )}
    </div>
  );

};

export default GangDetails;
