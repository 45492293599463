import React, { useEffect, useState } from 'react';
import useDashboardStore from '../../store/dashboardStore';
import useAuthStore from '../../store/authStore';
import { fetchPlayerStats } from '../../services/dashboardService';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isAuthenticated } = useAuthStore();
  const { t } = useTranslation();
  const { stats, setStats, error, setError } = useDashboardStore();

  // Countdown states (in milliseconds)
  const [nextRefillCountdown, setNextRefillCountdown] = useState(60000); // 1-minute countdown
  const [fullRefillCountdown, setFullRefillCountdown] = useState(null);

  // Initial fetch when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const statsData = await fetchPlayerStats();
        setStats(statsData);
        if (statsData) calculateCountdowns(statsData.current_stamina, statsData.max_stamina);
      } catch (err) {
        setError('Error fetching data');
      }
    };

    if (isAuthenticated) fetchData();
  }, [isAuthenticated, setStats, setError]);

  // Calculate initial countdowns based on stamina values
  const calculateCountdowns = (currentStamina, maxStamina) => {
    if (currentStamina < maxStamina) {
      setNextRefillCountdown(60000); // 1-minute countdown for next refill
      const staminaNeeded = maxStamina - currentStamina;
      const fullRefillTime = Math.ceil(staminaNeeded / 10) * 60000; // time needed for full refill
      setFullRefillCountdown(fullRefillTime);
    } else {
      setNextRefillCountdown(null);
      setFullRefillCountdown(null);
    }
  };

  // Start the countdown with setInterval
  useEffect(() => {
    const intervalId = setInterval(() => {
      setNextRefillCountdown((prev) => {
        if (prev === null || prev <= 0) {
          refreshStamina(); // Fetch updated stamina when countdown reaches zero
          return 60000; // Reset next refill countdown to 1 minute
        }
        return prev - 1000; // Decrement by 1 second
      });

      // Decrement full refill countdown if applicable
      setFullRefillCountdown((prev) => (prev && prev > 1000 ? prev - 1000 : 0));
    }, 1000); // 1-second interval

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [stats]);

  // Function to refresh stamina data
  const refreshStamina = async () => {
    try {
      const statsData = await fetchPlayerStats();
      setStats(statsData);
      calculateCountdowns(statsData.current_stamina, statsData.max_stamina);
    } catch (error) {
      setError('Error refreshing stamina');
    }
  };

  // Format countdown display as MM:SS
  const formatCountdown = (time) => {
    if (time <= 0) return '00:00';
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function CountdownDisplay({ nextRefillCountdown, fullRefillCountdown }) {
    return (
      <div>
        <p className="flex justify-between">
          <span className="font-bold">{t('Next Refill In')}:</span>
          <span>{formatCountdown(nextRefillCountdown)}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Full Refill In')}:</span>
          <span>{formatCountdown(fullRefillCountdown)}</span>
        </p>
      </div>
    );
  }

  if (error) return;
  if (!stats) return;

  return (
    <div className="flex flex-col items-center justify-center bg-black bg-opacity-60 p-2 rounded-lg shadow-lg w-72 border-2 border-[#DAA520] text-yellow-500">
      <div className="flex justify-between items-center w-full mb-4">
        <h3 className="text-2xl font-semibold">{t('Player_Stats')}</h3>
        <button
          onClick={toggleModal}
          className="bg-[#DAA520] text-white rounded-full px-2 py-1 ml-2"
          style={{ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.5)' }}
        >
          {t('FAQ.header')} ?
        </button>
      </div>

      <div className="space-y-2 text-left w-full">
        <p className="flex justify-between">
          <span className="font-bold">{t('Username')}:</span>
          <span>{stats.username}</span>
        </p>
        <p className="flex flex-col justify-between">
          <span className="font-bold">{t('Respect')}:</span>
          <span>{stats.respect}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Level')}:</span>
          <span>{stats.level}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('XP')}:</span>
          <span>{stats.xp}</span>
        </p>
        <p className="flex flex-col justify-between">
          <span className="font-bold">{t('Money')}:</span>
          <span>{stats.money}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Strength')}:</span>
          <span>{stats.strength}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Intelligence')}:</span>
          <span>{stats.intelligence}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Charisma')}:</span>
          <span>{stats.charisma}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Stamina')}:</span>
          <span>{stats.current_stamina}/{stats.max_stamina}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Next Refill In')}:</span>
          <span>{formatCountdown(nextRefillCountdown)}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Full Refill In')}:</span>
          <span>{formatCountdown(fullRefillCountdown)}</span>
        </p>
      </div>

      {/* FAQ Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={toggleModal}
        >
          <div
            className="bg-white rounded-lg relative p-8 max-w-lg w-full max-h-[80vh] overflow-y-auto shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-black text-xl"
              onClick={toggleModal}
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-4">{t('FAQ.title')}</h2>
            <ul className="list-disc pl-5">
              <li dangerouslySetInnerHTML={{ __html: t('FAQ.what_is_xp') }}></li>
              <li dangerouslySetInnerHTML={{ __html: t('FAQ.how_to_increase_level') }}></li>
              <li dangerouslySetInnerHTML={{ __html: t('FAQ.use_of_money') }}></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
