import React, { useEffect } from 'react';
import useBuildingsStore from '../store/buildingsStore';  // Zustand store
import {
  fetchUserIdAndBalance,
  fetchBuildings,
  fetchUserBuildings,
  buyBuilding,
  sellBuilding,
} from '../services/buildingsService';  // API services
import { t } from 'i18next';

const Buildings = () => {
  const {
    buildings,
    userBuildings,
    balance,
    userId,
    message,
    setBuildings,
    setUserBuildings,
    setBalance,
    setUserId,
    setMessage,
  } = useBuildingsStore();  // Access Zustand state

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user ID and balance
        const { userId, money } = await fetchUserIdAndBalance();
        setUserId(userId);
        setBalance(money);

        // Fetch buildings and user buildings
        const buildingsData = await fetchBuildings();
        setBuildings(buildingsData);
        const userBuildingsData = await fetchUserBuildings(userId);
        setUserBuildings(userBuildingsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [setUserId, setBalance, setBuildings, setUserBuildings]);

  const handleBuy = async (buildingId) => {
    if (!userId) {
      setMessage('User ID not found');
      return;
    }
    try {
      const response = await buyBuilding(userId, buildingId);
      setMessage(response.message);

      // Refresh balance and user buildings
      const { money } = await fetchUserIdAndBalance();
      setBalance(money);
      const userBuildingsData = await fetchUserBuildings(userId);
      setUserBuildings(userBuildingsData);
    } catch (error) {
      console.error('Error buying building:', error);
      setMessage('Failed to buy building');
    }
  };

  const handleSell = async (buildingId) => {
    if (!userId) {
      setMessage('User ID not found');
      return;
    }
    try {
      const response = await sellBuilding(userId, buildingId);
      setMessage(response.message);

      // Refresh balance and user buildings
      const { money } = await fetchUserIdAndBalance();
      setBalance(money);
      const userBuildingsData = await fetchUserBuildings(userId);
      setUserBuildings(userBuildingsData);
    } catch (error) {
      console.error('Error selling building:', error);
      setMessage('Failed to sell building');
    }
  };

  if (!buildings.length) {
    return <p>Loading buildings...</p>;
  }

  return (
    <div
      className="min-h-screen bg-cover bg-center flex justify-center items-center mobile-friendly"
    >
      <div
        className="buildings-container bg-black bg-opacity-60 p-8 rounded-lg text-left shadow-lg max-w-lg w-full mb-20 mt-20"
        style={{
          transform: 'translateY(0px)', // Move the container 50px closer to the top
        }}
      >

        <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Buildings')}</h2>

        <div className="text-white">
          <p dangerouslySetInnerHTML={{ __html: t('BuildingsDesc') }}></p>
          <br />
          <p className="text-red-600 font-bold" dangerouslySetInnerHTML={{ __html: t('BuildingsDesc2') }}></p>
          <br />
        </div>

        <p className="text-yellow-500">{t('Balance')}: {balance}</p>
        <br />
        {message && <p className="text-red-500">{message}</p>}

        <ul className="mb-6">
          {buildings.map((building) => (
            <li key={building.id} className="mb-4 text-white flex flex-col items-start">
              <p>
                {t(building.name)} - {t('Cost')}: {building.cost}, {t('ProductionRate')}: {building.production_rate}
              </p>
              <button
                onClick={() => handleBuy(building.id)}
                className="mt-2 bg-yellow-500 text-white px-4 py-2 rounded">
                {t('Buy')}
              </button>
            </li>
          ))}
        </ul>

        <h3 className="text-2xl font-semibold mb-4 text-yellow-500">{t('YourBuildings')}</h3>
        <ul>
          {userBuildings.length === 0 ? (
            <p className='text-white'>{t('NoBuildings')}</p>
          ) : (
            userBuildings.map((building) => {
              const casualRate = building.production_rate;
              const premiumRate = casualRate * 1.1; // Add 10% bonus for premium users
              const totalProduction = building.quantity * casualRate;

              return (
                <li key={building.id} className="mb-4 text-white flex flex-col items-start">
                  <p>{building.name}</p>
                  <p>{t('Quantity')}: {building.quantity}</p>
                  <p>{t('Casual Production Rate')}: {casualRate}</p>
                  <p>{t('Premium Production Rate')}: {premiumRate.toFixed(1)}</p>
                  <p>{t('Your Total Production Rate')}: {totalProduction.toFixed(1)}</p>
                  <button
                    className="mt-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                    onClick={() => handleSell(building.id)}>
                    {t('Sell')}
                  </button>
                </li>
              );
            })
          )}
        </ul>
      </div>
    </div >
  );
};

export default Buildings;
