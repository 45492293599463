// privateMessageStore.js
import { create } from 'zustand';

const useMessageStore = create((set) => ({
  inbox: [],
  outbox: [],
  username: '',
  unreadCount: 0,
  setInbox: (inbox) => set({ inbox }),
  setOutbox: (outbox) => set({ outbox }),
  setUsername: (username) => set({ username }),
  setUnreadCount: (count) => set({ unreadCount: count }),
}));

export default useMessageStore;
