// frontend/src/services/buildingsService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUserIdAndBalance = async () => {
  const response = await axios.get(`${API_URL}/stats`, {
    withCredentials: true,
  });
  return response.data; // { userId, money }
};

export const fetchBuildings = async () => {
  const response = await axios.get(`${API_URL}/buildings`, {
    withCredentials: true,
  });
  return response.data;
};

export const fetchUserBuildings = async (userId) => {
  const response = await axios.get(`${API_URL}/buildings/${userId}`, {
    withCredentials: true,
  });
  return response.data;
};

export const buyBuilding = async (userId, buildingId) => {
  const response = await axios.post(
    `${API_URL}/buildings/buy`,
    { userId, buildingId },
    { withCredentials: true }
  );
  return response.data; // { message }
};

export const sellBuilding = async (userId, buildingId) => {
  const response = await axios.post(
    `${API_URL}/buildings/sell`,
    { userId, buildingId },
    { withCredentials: true }
  );
  return response.data; // { message }
};
