// frontend/src/store/bankStore.js
import { create } from 'zustand';

const useBankStore = create((set) => ({
  bankBalance: 0,
  walletBalance: 0,
  userId: null,
  message: '',
  amount: '',

  setBankBalance: (balance) => set({ bankBalance: balance }),
  setWalletBalance: (balance) => set({ walletBalance: balance }),
  setUserId: (id) => set({ userId: id }),
  setMessage: (message) => set({ message }),
  setAmount: (amount) => set({ amount }),
  clearMessage: () => set({ message: '' }),
  clearAll: () => set({ message: '', amount: '' }),
}));

export default useBankStore;
