// frontend/src/store/gangRobberyStore.js
import { create } from 'zustand';

const useGangRobberyStore = create((set) => ({
  // States
  requirements: null, 
  result: '',
  robberyType: '',
  plannedRobbery: null,  // Stores the current planned robbery
  acceptedMembers: [],   // Array of members who accepted
  showResult: false,     // New state for showing only result
  teamMeetsRequirements: true,

  // Setters
  setRequirements: (requirements) => set({ requirements }),
  setResult: (result) => set({ result }),
  setRobberyType: (robberyType) => set({ robberyType }),
  setPlannedRobbery: (robbery) => set({ plannedRobbery: robbery }),
  setShowResult: (show) => set({ showResult: show }),
  setTeamMeetsRequirements: (meets) => set({ teamMeetsRequirements: meets }),
  
  // Member management
  addAcceptedMember: (memberId) => set((state) => ({
    acceptedMembers: [...state.acceptedMembers, memberId]
  })),

  // Clear everything
  clearRobbery: () => set({ 
    result: '', 
    plannedRobbery: null, 
    acceptedMembers: [],
    robberyType: '',
    showResult: false,
    requirements: null,  // Add this to clear
    teamMeetsRequirements: true  // Reset this as well
  }),
}));

export default useGangRobberyStore;
