import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGangsStore from '../store/gangsStore';
import useGangRobberyStore from '../store/gangRobberyStore';
import {
    planGangRobbery,
    acceptRobbery,
    executeGangRobbery,
    fetchCurrentRobbery,
    fetchRequirements as fetchRobberyRequirements,
    cancelRobbery
} from '../services/gangRobberyService';
import { fetchUserId, fetchUserGang } from '../services/gangsService';

const GangRobbery = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [robberyType, setRobberyType] = useState('');
    const [requirements, setRequirements] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState('');
    const [message, setMessage] = useState('');

    const { userId, setUserId, userGang, setUserGang } = useGangsStore();
    const {
        plannedRobbery,
        setPlannedRobbery,
        teamMeetsRequirements,
        setTeamMeetsRequirements
    } = useGangRobberyStore();

    // Initialize user and gang information
    useEffect(() => {
        const initializeData = async () => {
            try {
                const userIdData = await fetchUserId();
                setUserId(userIdData.userId);

                if (userIdData.userId) {
                    const gangData = await fetchUserGang(userIdData.userId);
                    setUserGang(gangData);
                }
            } catch (error) {
                console.error('Error initializing data:', error);
                setResult(t('ErrorLoadingData'));
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [setUserId, setUserGang, setResult, t]);

    // Refresh current robbery data every 5 seconds
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!userGang?.id) return;

                const currentRobbery = await fetchCurrentRobbery(userGang.id);
                if (currentRobbery) {
                    setPlannedRobbery(currentRobbery);
                    setRobberyType(currentRobbery.robbery_type);
                    setTeamMeetsRequirements(!currentRobbery.error_message?.includes("doesn't meet the required stats"));
                }
            } catch (error) {
                console.error('Error fetching robbery data:', error);
            }
        };

        fetchData();
        const interval = setInterval(fetchData, 5000);
        return () => clearInterval(interval);
    }, [userGang?.id, setPlannedRobbery]);

    // Fetch requirements when robberyType changes
    useEffect(() => {
        const getRequirements = async () => {
            if (robberyType) {
                try {
                    const fetchedRequirements = await fetchRobberyRequirements(robberyType);
                    setRequirements(fetchedRequirements);
                } catch (error) {
                    setMessage(t('ErrorFetchingRequirements'));
                }
            }
        };
        getRequirements();
    }, [robberyType, t]);

    const handlePlanRobbery = async () => {
        if (!userGang?.id) return;

        setMessage('');
        setLoading(true);
        try {
            const data = await planGangRobbery(robberyType, userGang.id);
            setPlannedRobbery(data);
            setResult(t('RobberyPlannedSuccess'));
            setShowResult(true);
        } catch (error) {
            setResult(error.response?.data?.message || t('ErrorPlanningRobbery'));
            setShowResult(true);
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptRobbery = async () => {
        if (!userGang?.id) return;

        try {
            await acceptRobbery(userGang.id);
            const updatedRobbery = await fetchCurrentRobbery(userGang.id);
            setPlannedRobbery(updatedRobbery);
            setResult(t('RobberyAcceptedSuccess'));
            setShowResult(true);
        } catch (error) {
            const errorMessage = error.response?.data?.message || t('ErrorAcceptingRobbery');
            setTeamMeetsRequirements(!errorMessage.includes("doesn't meet the required stats"));
            setResult(errorMessage);
            setShowResult(true);
        }
    };

    const handleExecuteRobbery = async () => {
        if (!userGang?.id) return;

        try {
            const data = await executeGangRobbery(userGang.id);
            if (data.success) {
                const { money, xp, gainedStats } = data;
                const formattedResult = `${t('RobberySuccess')}
                    ${t('GainedMoney')}: ${money}$
                    ${t('GainedXP')}: ${xp}
                    ${t('GainedStrength')}: +${gainedStats?.strength || 0}
                    ${t('GainedIntelligence')}: +${gainedStats?.intelligence || 0}
                    ${t('GainedCharisma')}: +${gainedStats?.charisma || 0}`;
                setResult(formattedResult);
            } else {
                setResult(data.message || t('ErrorExecutingRobbery'));
            }

            setShowResult(true);
        } catch (error) {
            setResult(error.response?.data?.message || t('ErrorExecutingRobbery'));
            setShowResult(true);
        }
    };

    const handleCancelRobbery = async () => {
        if (!userGang?.id) return;

        try {
            await cancelRobbery(userGang.id);
            setPlannedRobbery(null);
            setResult(t('RobberyCancelled'));
            setShowResult(true);
        } catch (error) {
            console.error('Cancel error:', error);
            setResult(error.response?.data?.message || t('ErrorCancellingRobbery'));
            setShowResult(true);
        }
    };

    if (loading) {
        return <div className="text-center">{t('Loading')}</div>;
    }

    if (!userGang) {
        return <div className="text-center">{t('NoGangMessage')}</div>;
    }

    return (
        <div className="h-screen bg-cover bg-center flex justify-center items-center"
            >
            <div className="bg-black bg-opacity-60 rounded-lg p-8 text-left shadow-lg"
                style={{ border: '2px solid #DAA520', color: '#fff' }}>
                <h2 className="text-4xl font-bold mb-4 text-yellow-500 text-center">
                    {t('GangRobberyTitle')}
                </h2>

                {showResult ? (
                    <p className="mt-4 text-white whitespace-pre-line">{result}</p>
                ) : (
                    <>
                        {!plannedRobbery ? (
                            <div>
                                <select
                                    value={robberyType}
                                    onChange={(e) => setRobberyType(e.target.value)}
                                    className="p-2 rounded bg-gray-400 text-white mb-4 w-full"
                                >
                                    <option value="">{t('SelectRobberyType')}</option>
                                    <option value="gang_small_heist">{t('SmallHeist')}</option>
                                    <option value="gang_bank_heist">{t('BankHeist')}</option>
                                    <option value="gang_casino_heist">{t('CasinoHeist')}</option>
                                </select>
                                <button
                                    onClick={handlePlanRobbery}
                                    className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-600 w-full"
                                >
                                    {t('PlanRobbery')}
                                </button>
                                    {requirements && (
                                        <div className="text-yellow-500 mt-4">
                                            <h3><b>{t('Requirements')}:</b></h3>
                                            <p>{t('Required_Members')}: {requirements.requirements.requiredMembers || 'N/A'}</p>
                                            <p>{t('Stamina Cost')}: {requirements.requirements.staminaCost || 'N/A'}</p>
                                            <p>{t('Strength')}: {requirements.requirements.requiredStats?.strength || 'N/A'}</p>
                                            <p>{t('Intelligence')}: {requirements.requirements.requiredStats?.intelligence || 'N/A'}</p>
                                            <p>{t('Charisma')}: {requirements.requirements.requiredStats?.charisma || 'N/A'}</p>
                                        </div>
                                    )}

                            </div>
                        ) : (
                            <div>
                                <p className="mb-4">{t('PlannedRobberyInfo')}: {t(plannedRobbery.robbery_type)}</p>
                                <p className="mb-2">{t('AcceptedMembers')}:</p>
                                {plannedRobbery.participants?.map((participant, index) => (
                                    <p key={index} className="ml-4 mb-1">{participant.username}</p>
                                ))}
                                <p className="mt-2 mb-4">
                                    {plannedRobbery.participants?.length || 0}/{plannedRobbery.required_members}
                                </p>
                                <div className="flex flex-col gap-2">
                                    {plannedRobbery.participants &&
                                        plannedRobbery.participants.length < plannedRobbery.required_members &&
                                        !plannedRobbery.participants.some(participant => participant.user_id === userId) && (
                                            <button
                                                onClick={handleAcceptRobbery}
                                                className="bg-green-500 text-black px-4 py-2 rounded hover:bg-green-600 w-full"
                                            >
                                                {t('AcceptRobbery')}
                                            </button>
                                        )}
                                    {plannedRobbery.participants &&
                                        plannedRobbery.participants.length >= plannedRobbery.required_members &&
                                        teamMeetsRequirements && (
                                            <button
                                                onClick={handleExecuteRobbery}
                                                className="bg-red-500 text-black px-4 py-2 rounded hover:bg-red-600 w-full"
                                            >
                                                {t('ExecuteRobbery')}
                                            </button>
                                        )}
                                    {(plannedRobbery.creator_username === userId || userGang?.leader_id === userId) && (
                                        <button
                                            onClick={handleCancelRobbery}
                                            className="bg-gray-500 text-black px-4 py-2 rounded hover:bg-gray-600 w-full mt-2"
                                        >
                                            {t('CancelRobbery')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}

                {message && <p className="mt-4 text-red-500">{message}</p>}
            </div>
        </div>
    );
};

export default GangRobbery;
