import React, { useEffect, useState } from 'react';
import { fetchUserProfile } from '../../services/playerStatsService';
import { checkUserPremium, fetchEquipment } from '../../services/usersService';
import useAuthStore from '../../store/authStore';
import useArmoryStore from '../../store/armoryStore';
import useWeaponsStore from '../../store/weaponsStore';
import useGangsStore from '../../store/gangsStore';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

const UserProfile = () => {
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuthStore();
  const { userGang, gangName } = useGangsStore();

  // Zustand stores
  const { armoryItems, setArmoryItems } = useArmoryStore();
  const { weaponsItems, setWeaponsItems } = useWeaponsStore();

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Fetch user profile and premium status
        const profileData = await fetchUserProfile(user.id);
        const premiumData = await checkUserPremium(user.id);

        setProfile({
          ...profileData,
          premium: premiumData.premium,
          gang: userGang ? gangName : 'No gang',
        });

        // Fetch equipment data and update Zustand stores
        const equipmentData = await fetchEquipment(user.id);

        // Safely handle the structure of equipmentData
        const equipmentArray = Array.isArray(equipmentData)
          ? equipmentData
          : Array.isArray(equipmentData?.equipment)
            ? equipmentData.equipment
            : [];

        // Update Zustand stores for weapons and armor
        setWeaponsItems(
          equipmentArray.filter((item) => item.type === 'weapon') || []
        );
        setArmoryItems(
          equipmentArray.filter((item) => item.type === 'armor') || []
        );
      } catch (err) {
        console.error('Error fetching profile details:', err);
        setError('Error fetching profile');
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated && user?.id) {
      setLoading(true); // Explicitly set loading state before fetching
      setError(null);
      fetchProfile();
    }
  }, [isAuthenticated, user, userGang, gangName, setWeaponsItems, setArmoryItems]);

  if (error) return <p>{t('Error fetching profile')}</p>;
  if (loading) return <p>{t('Loading...')}</p>;

  const equippedWeapon = weaponsItems.length > 0 ? weaponsItems[0] : null; // First weapon as equipped
  const equippedArmory = armoryItems.length > 0 ? armoryItems[0] : null; // First armor as equipped

  return (
    <div className="flex flex-col items-center justify-center bg-black bg-opacity-60 p-2 rounded-lg shadow-lg w-72 border-2 border-[#DAA520] text-yellow-500">
      <h2 className="text-2xl font-bold mb-4">{t('Profile')}</h2>
      <div className="space-y-2 text-left w-full">
        <p className="flex justify-between">
          <span className="font-bold">{t('Premium')}:</span>
          <span className={`${profile.premium ? 'text-green-500' : 'text-red-500'} font-bold`}>
            {profile.premium ? 'Yes' : 'No'}
          </span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('ID')}:</span>
          <span>{user.id}</span>
        </p>
        <p className="flex flex-col justify-between">
          <span className="font-bold">{t('Gang')}:</span>
          <span>{profile.gang}</span>
        </p>
        <p className="flex flex-col justify-between">
          <span className="font-bold">{t('Weapon')}:</span>
          <span>{equippedWeapon ? equippedWeapon.item_name : 'None'}</span>
        </p>
        <p className="flex flex-col justify-between">
          <span className="font-bold">{t('Armor')}:</span>
          <span>{equippedArmory ? equippedArmory.item_name : 'None'}</span>
        </p>
        <p className="flex justify-between">
          <span className="font-bold">{t('Kills')}:</span>
          <span>{profile.kills}</span>
        </p>
        <div className="flex flex-col justify-between">
          <span className="font-bold">{t('CrimesCommitted')}:</span>
          <span>{profile.crimes_committed}</span>
        </div>

        <p className="flex justify-between">
          <span className="font-bold">{t('Joined')}:</span>
          <span>{new Date(profile.created_at).toLocaleDateString()}</span>
        </p>
      </div>
    </div>
  );
};

export default UserProfile;
