import React, { useState, useEffect } from 'react';
import { registerUser } from '../services/authService';  // Import the register service
import useAuthStore from '../store/authStore';  // Import Zustand store
import { useTranslation } from 'react-i18next';
import '../i18n/i18n';

function Register() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  });

  const { isAuthenticated, setUser } = useAuthStore();  // Access Zustand for auth logic
  const [isRulezChecked, setIsRulezChecked] = useState(false);
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [showRulezModal, setShowRulezModal] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      console.log('auth success');
    } else {
      console.log('auth failed');
    }
  }, [isAuthenticated]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await registerUser(formData);
      setUser(user);
      window.location.href = '/login';
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data.message); // Display the error message to the user
      } else if (error.message) {
        alert(error.message); // Display the error message to the user
      } else {
        alert('An error occurred. Please try again.'); // Display a generic error message to the user
      }
    }
  };

  return (
    <div className="min-h-[85vh] bg-cover bg-center flex justify-center items-center" style={{ backgroundImage: `url('./skull.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="bg-black bg-opacity-75 rounded-lg p-8 text-center shadow-lg w-96 border-2 border-yellow-500">
        <h2 className="text-3xl font-bold mb-4 text-yellow-500">{t('Register')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="block text-white text-sm font-bold" htmlFor="username">{t('Username')}:</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent border-yellow-500"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-white text-sm font-bold" htmlFor="email">Email:</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent border-yellow-500"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-2">
            <label className="block text-white text-sm font-bold" htmlFor="password">{t('Password')}:</label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline bg-transparent border-yellow-500"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <input type="checkbox" id="rulez" checked={isRulezChecked} onChange={() => setShowRulezModal(true)} className="mb-4" />
          <label className="text-white text-sm font-bold"> {t('accept_rules')}</label>
          <br />
          <input type="checkbox" id="policy" checked={isPolicyChecked} onChange={() => setIsPolicyChecked(!isPolicyChecked)} className="mb-4" />
          <label className="text-white text-sm font-bold"> {t('accept_policy')}</label>

          <button
            type="submit"
            className={`bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded w-full ${(!isRulezChecked || !isPolicyChecked) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            disabled={!isRulezChecked || !isPolicyChecked}
          >
            Register
          </button>

          {/* Rules Modal */}
          {showRulezModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-black border-2 border-yellow-500 rounded-lg p-6 max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold text-yellow-500">Rulezz</h3>
                  <button
                    onClick={() => setShowRulezModal(false)}
                    className="text-yellow-500 hover:text-yellow-600"
                  >
                    ×
                  </button>
                </div>

                <div className="mb-6 text-white">
                  <p className="mb-4">
                    1. The First rule of GangBangers is: you do not talk about GangBangers<br />
                    2. The Second rule of GangBangers is: you DO NOT talk about GangBangers<br />
                    3. No disrespect to the Hells Angels<br />
                    4. No disrespect to other users<br />
                    5. Multiple accounts may OR may not be allowed<br />
                    6. AutoClick may OR may not be allowed<br />
                    7. Feel free to curse and swear to people its GANG BANGERS<br />
                    8. Be aware of rule 5 and 6 they can BE change any time<br />
                    9. No gender shit<br />
                    10. No politic shit<br />
                    11. Dont be fucking toxic<br />
                    12. Yes you can play while u r drunk/high<br />
                    <font color="red">13. <b>By clicking "ACCEPT" you will be on responsible on these rules</b></font>
                  </p>
                </div>

                <div className="flex justify-end gap-4">
                  <button
                    onClick={() => {
                      setShowRulezModal(false);
                      setIsRulezChecked(false);
                    }}
                    className="px-4 py-2 text-yellow-500 hover:text-yellow-600"
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => {
                      setShowRulezModal(false);
                      setIsRulezChecked(true);
                    }}
                    className="px-4 py-2 bg-yellow-500 text-black rounded hover:bg-yellow-600"
                  >
                    ACCEPT
                  </button>
                </div>
              </div>
            </div>
          )}




        </form>
      </div>
    </div>
  );
}

export default Register;
