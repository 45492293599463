// AvailableGangs.js
import React, { useEffect } from 'react';
import useGangsStore from '../../store/gangsStore';
import { fetchUserGang, fetchAllGangs, createGang, sendJoinRequest, fetchUserJoinRequests, deleteJoinRequest } from '../../services/gangsService';
import { useTranslation } from 'react-i18next';
import '../../i18n/i18n';

const AvailableGangs = () => {
  const { t } = useTranslation();
  const {
    gangs,
    setGangs,
    setGangName,
    gangName,
    setMessage,
    userId,
    setUserGang,
    message,
    userJoinRequests,
    setUserJoinRequests,
    username,
  } = useGangsStore();

  // Fetch available gangs on component load using `fetchAllGangs`
  useEffect(() => {
    const loadGangs = async () => {
      try {
        const gangData = await fetchAllGangs(); // Fetch all gangs
        setGangs(gangData); // Update store with fetched gangs
      } catch (error) {
        console.error('Error loading gangs:', error);
        setMessage('Failed to load gangs');
      }
    };

    loadGangs();
  }, [setGangs, setMessage]);

  // Fetch join requests if userId or username is set
  useEffect(() => {
    if (!userId && !username) return;

    const fetchData = async () => {
      try {
        const data = await fetchUserJoinRequests();
        setUserJoinRequests(data);
      } catch (error) {
        console.error('Error fetching join requests:', error);
        setMessage('Failed to fetch join requests');
      }
    };

    fetchData();
  }, [userId, username, setUserJoinRequests, setMessage]);

  const handleCreateGang = async () => {
    if (!gangName) {
      setMessage('Please enter a gang name.');
      return;
    }

    try {
      const response = await createGang(userId, gangName);
      setMessage(response.message);
      const updatedUserGang = await fetchUserGang(userId);
      setUserGang(updatedUserGang);
    } catch (error) {
      console.error('Error creating gang:', error);
      setMessage('Failed to create gang.');
    }
  };

  const handleJoinRequest = async (gangId) => {
    const { userId } = useGangsStore.getState();

    if (!userId) {
      setMessage('User is not logged in or user ID is not available.');
      return;
    }

    try {
      const response = await sendJoinRequest(gangId, userId);
      setMessage(response.message);

      // Refresh join requests after sending join request
      const updatedJoinRequests = await fetchUserJoinRequests();
      setUserJoinRequests(updatedJoinRequests);

    } catch (error) {
      console.error('Error sending join request:', error);
      setMessage('Failed to send join request.');
    }
  };

  const handleDeleteJoinRequest = async (gangId) => {
    try {
      await deleteJoinRequest(userId, gangId);
      setMessage('Join request canceled successfully.');

      // Refresh join requests after deleting
      const updatedJoinRequests = await fetchUserJoinRequests();
      setUserJoinRequests(updatedJoinRequests);
    } catch (error) {
      console.error('Error deleting join request:', error);
      setMessage('Failed to delete join request.');
    }
  };

  return (
    <div
      className="h-auto bg-cover bg-center flex justify-center items-center py-10"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div
        className="bg-black bg-opacity-60 p-6 rounded-lg text-left shadow-lg max-w-md w-full mb-10 mt-10"
        style={{
          transform: 'translateY(0px)',
        }}
      >
        <h3 className="text-3xl font-bold mb-4 text-yellow-500">{t('AvailableGangs')}</h3>
        <ul className="mb-6 space-y-4">
          {gangs.length > 0 ? (
            gangs.map((gang) => (
              <li key={gang.id} className="text-white p-4 flex flex-col bg-gray-800 rounded-md hover:bg-gray-700 transition duration-200">
                <div className="flex justify-between items-center">
                  <span className="text-lg font-semibold">{gang.name}</span>
                  <button
                    onClick={() => handleJoinRequest(gang.id)}
                    className="bg-yellow-500 text-black font-semibold px-3 py-1 rounded hover:bg-yellow-600 transition duration-300"
                  >
                    {t('Join')}
                  </button>
                </div>
                <div className="mt-2 text-sm text-yellow-400">
                  <p>Leader: <span className="font-medium">{gang.leader_username || 'N/A'}</span></p>
                  <p>Members: <span className="font-medium">{gang.members_count ?? 0}</span></p>
                </div>
              </li>
            ))
          ) : (
            <p className="text-gray-400">No gangs available</p>
          )}
        </ul>

        <h3 className="text-2xl font-bold mb-4 text-yellow-500">{t('CreateGang')}</h3>
        <div className="flex flex-col sm:flex-row gap-4 mb-6">
          <input
            type="text"
            value={gangName}
            onChange={(e) => setGangName(e.target.value)}
            placeholder="Enter gang name"
            className="p-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <button
            onClick={handleCreateGang}
            className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 transition duration-300"
          >
            {t('CreateGang')}
          </button>
        </div>
        {message && <p className="text-red-500 mt-2">{message}</p>}

        {userJoinRequests.length > 0 && (
          <div className="mt-6">
            <h3 className="text-lg font-bold text-yellow-500 mb-2">Pending Join Requests</h3>
            <ul className="space-y-2">
              {userJoinRequests.map((joinRequest) => (
                <li
                  key={joinRequest.gangId}
                  className="flex justify-between items-center bg-gray-800 p-3 rounded-md hover:bg-gray-700 transition duration-200"
                >
                  <span className="text-white text-lg">{joinRequest.gangName}</span>
                  <span className="text-yellow-400 font-semibold">
                    <button

                      onClick={() => handleDeleteJoinRequest(joinRequest.gangId)}

                      className="text-red-500 hover:text-red-700 transition duration-300"

                    >

                      {t('Cancel Request')}

                    </button>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}

      </div>
    </div>
  );
};

export default AvailableGangs;
