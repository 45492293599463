// frontend/src/store/authStore.js
import { create } from 'zustand';

const API_URL = process.env.REACT_APP_API_URL;

const useAuthStore = create((set) => ({
    user: null,
    isAuthenticated: false,
    loading: true,
    setUser: (user) => {
        set({ user, isAuthenticated: !!user, loading: false });
    },

    checkAuthStatus: async () => {
        try {
            const response = await fetch(`${API_URL}/auth/status`, {
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok) {
                const data = await response.json();
                set({ user: data.user, isAuthenticated: true, loading: false });
            } else if (response.status === 403 || response.status === 503) {
                console.warn('User is not authenticated (403 Forbidden)');
                // Suppress 403 Forbidden error by not logging anything
                set({ user: null, isAuthenticated: false, loading: false }); 
            } else {
                console.warn(`Unexpected status code: ${response.status}`);
                set({ user: null, isAuthenticated: false, loading: false });;
            }
        } catch (error) {
            if (!error.message.includes('Failed to fetch') && !error.message.includes('CORS')) {
                console.error("Unexpected error checking auth status:", error);
            }
            console.error('Failed to check authentication status:', error.message);
            set({ user: null, isAuthenticated: false, loading: false });
        }
    },
    logout: async () => {
        try {
            const response = await fetch(`${API_URL}/auth/logout`, {
                method: 'POST',
                credentials: 'include',
            });
            if (response.ok) {
                set({ user: null, isAuthenticated: false, loading: false });
            }
        } catch (error) {
            console.error('Failed to logout:');
        }
    },
}));

export default useAuthStore;
