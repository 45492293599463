// src/store/attackStore.js
import { create } from 'zustand';

const API_URL = process.env.REACT_APP_API_URL;

const useAttackStore = create((set) => ({
  username: '',
  result: null,
  error: null,
  setUsername: (username) => set({ username }),
  attack: async () => {
    set({ result: null, error: null });
    try {
      const victim = useAttackStore.getState().username;
      console.log('Attempting attack on:', victim);
      console.log('API URL:', `${API_URL}/attack/${victim}`);

      const response = await fetch(`${API_URL}/attack/${victim}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      const responseData = await response.json();

      if (response.ok) {
        set({ result: responseData });
      } else {
        throw new Error(responseData.error || 'Attack request failed');
      }
    } catch (err) {
      console.error('Attack error:', err);
      set({ error: err.message || 'Attack request failed' });
    }
  },
  reset: () => set({ username: '', result: null, error: null }),
}));

export default useAttackStore;
