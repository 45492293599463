// frontend/src/store/buildingsStore.js
import { create } from 'zustand';

const useBuildingsStore = create((set) => ({
  buildings: [],
  userBuildings: [],
  balance: 0,
  userId: null,
  message: '',

  setBuildings: (buildings) => set({ buildings }),
  setUserBuildings: (userBuildings) => set({ userBuildings }),
  setBalance: (balance) => set({ balance }),
  setUserId: (id) => set({ userId: id }),
  setMessage: (message) => set({ message }),
}));

export default useBuildingsStore;
